import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import {social} from '../constants'

class SideMenu extends Component {
    render(){
        const t = this.props.t;

        return (
          <div className="offcanvas-widget" style={{backgroundImage: `url(assets/img/icon/pattern-02.png)`}}>
            <div className="widget text-center">
                <img className="img-fluid" src="assets/img/logo.png" alt="" />
            </div>
            <Link to="#" className="offcanvas-close" onClick={this.props.removeClass}><i className="fal fa-times" /></Link>
            {/* Search Widget */}
            {/* <div className="widget search-widget">
              <h5 className="widget-title">Search room</h5>
              <form action="#">
                <input type="text" placeholder="Search your keyword..." />
                <button type="submit"><i className="far fa-search" /></button>
              </form>
            </div> */}
            {/* Nav Widget */}
            <div className="widget nav-widget">
              <h5 className="widget-title">{t('sidemenu.menu')}</h5>
              <ul className="pl-4">
                <li><Link to="/about">{t('about.us')}</Link></li>
                <li><Link to="/restaurant">{t('restaurant.name')}</Link></li>
                {/* <li><Link to="/menu">{t('sidemenu.food')}</Link></li> */}
                <li>
                  <Link to="/places">{t('sidemenu.places')}</Link>
                  {/* <ul className="submenu">
                    <li><Link to="/places-details">Places Details</Link></li>
                  </ul> */}
                </li>
                <li><Link to="/offers">{t('room.plr')}</Link></li>
                <li><Link to="/gallery">{t('sidemenu.gallery')}</Link></li>
              </ul>
            </div>
            {/* Social Link */}
            <div className="widget social-link text-center">
              <ul>
                {
                  social.length ? social.map((el, i) => (
                    <li key={i}><a href={el.link} target="_blank" rel="noopener noreferrer"><i className={el.icon} /></a></li>
                  )) : null
                }
              </ul>
            </div>
            {/* About Widget */}
            <div className="widget about-widget text-center">
              <p className="small">
                {t('footer.description')}
              </p>
            </div>
          </div>
        )
    }
}

export default SideMenu;