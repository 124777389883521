import React, {Component} from 'react'
import i18n from 'i18next'
import {rooms} from '../constants'
import DatePicker, { registerLocale, setDefaultLocale } from "react-datepicker";
import {tr, enUS, fr, ru} from 'date-fns/locale';

registerLocale('tr-TR', tr);
registerLocale('en-EN', enUS);
registerLocale('fr-FR', fr);
registerLocale('ru-RU', ru);

const today = new Date();

class Roomsidebar extends Component {  
  constructor(props) {
    super(props);
    this.state = {
       checkIn: today,
       checkOut: this.nextDay(today)
    };

    this.setCheckIn = this.setCheckIn.bind(this);
    this.setCheckOut = this.setCheckOut.bind(this);
    this.nextDay = this.nextDay.bind(this);
  }

  nextDay(d){
    let n = new Date(d);
    return n.setDate(n.getDate() + 1);
  }

  setCheckIn(d){
    this.setState({
      checkIn: d,
      checkOut: this.nextDay(d)
    });
  }

  setCheckOut(d){
    this.setState({
      checkOut: d
    });
  }

  render() {

    const t = o => {
      return i18n.t(o);
    }

    const getNumNights = () =>{
        var start = new Date(this.state.checkIn); 
        var end = new Date(this.state.checkOut);     
        var diff = end.getTime() - start.getTime();

        return parseInt(Math.round(diff / (1000 * 3600 * 24)));
    }


    let lang = i18n.language;
    setDefaultLocale(lang)
    lang  += "-" + lang.toUpperCase();
    
    const path = window.location.pathname.replace('/','');

    const looper = (s,e) => {
      let o = [];

      for (let i = s; i <= e; i++) {
          o[i] = i;
      }

      return o;
    }


    return (
      <div className="room-booking-form">
        <h5 className="title">{t('booking.checkav')}</h5>
        <form action={"https://mrbeyazbutikhotel.istbooking.com/"+ lang}>
          <input type="number" value="1" name="sr" id="sr" hidden readOnly />
          <div className="input-group input-group-two left-icon mb-20">
            <label htmlFor="ci">{t('booking.checkin')}</label>
            <DatePicker dateFormat="dd.MM.yyyy" selected={this.state.checkIn} minDate={today} onChange={date => this.setCheckIn(date)} name="ci" id="ci" locale={lang} />
            <div className="icon"><i className="fal fa-calendar-alt" /></div>
          </div>
          <div className="input-group input-group-two left-icon mb-20">
            <label htmlFor="ci">{t('booking.checkout')}</label>
            <DatePicker dateFormat="dd.MM.yyyy" selected={this.state.checkOut} minDate={this.nextDay(this.state.checkIn)} onChange={date => this.setCheckOut(date)} locale={lang} />
            <div className="icon"><i className="fal fa-calendar-alt" /></div>
            <input type="number" value={getNumNights()} name="ng"  hidden readOnly />
          </div>
          <div className="input-group input-group-two left-icon mb-20">
            <label htmlFor="ri">{t('room.plr')}</label>
            <select name="ri" id="ri" defaultValue={path}>
              {
                rooms.map(el => (
                  <option key={el.url} value={el.url.replace('/','')}>{el.title}</option>
                ))
              }
            </select>
            <div className="icon"><i className="fas fa-chevron-down" /></div>
          </div>
          <div className="input-group input-group-two left-icon mb-20">
              <label htmlFor="ro">{t('booking.num_rooms')}</label>
              <select name="ro" id="ro">
                  {
                    looper(1,10).map(el => (
                      <option key={'ro'+el} value={el}>{el}</option>
                    ))
                  }
              </select>
            <div className="icon"><i className="fas fa-chevron-down" /></div>
          </div>
          <div className="input-group input-group-two left-icon mb-20">
              <label htmlFor="ad">{t('booking.guest')}</label>
              <select name="ad" id="ad">
                  {
                    looper(1,4).map(el => (
                      <option key={'ad'+el} value={el}>{el}</option>
                    ))
                  }
              </select>
            <div className="icon"><i className="fas fa-chevron-down" /></div>
          </div>
          <div className="input-group input-group-two left-icon mb-20">
              <label htmlFor="c1">{t('booking.kid')}</label>
              <select name="c1" id="c1">
                  {
                    looper(0,3).map(el => (
                      <option key={'c1'+el} value={el}>{el}</option>
                    ))
                  }
              </select>
            <div className="icon"><i className="fas fa-chevron-down" /></div>
          </div>
          <div className="input-group input-group-two left-icon mb-20">
              <label htmlFor="c2">{t('booking.children')}</label>
              <select name="c2" id="c2">
                  {
                    looper(0,3).map(el => (
                      <option key={'c2'+el} value={el}>{el}</option>
                    ))
                  }
              </select>
            <div className="icon"><i className="fas fa-chevron-down" /></div>
          </div>
          <div className="input-group">
            <button className="main-btn btn-filled">{t('booking.checkav')}</button>
          </div>
        </form>
      </div>
    );
  }
}

export default Roomsidebar;
