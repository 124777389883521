import React, { Component } from 'react';
import { Link } from 'react-router-dom'; 
import Slider from "react-slick";
import {rooms} from '../../constants'

function SampleNextArrow(props) {
    const { onClick } = props;
    return (
      <div className="slick-arrow next-arrow" onClick={onClick}><i className="fal fa-arrow-right"></i></div>
    );
  }
  
  function SamplePrevArrow(props) {
    const { onClick } = props;
    return (
      <div className="slick-arrow prev-arrow" onClick={onClick}><i className="fal fa-arrow-left"></i></div>
    );
  }
class Featureroom extends Component {
    constructor(props) {
        super(props);
        this.next = this.next.bind(this);
        this.prev = this.prev.bind(this);
      }
      next() {
        this.slider.slickNext();
      }
      prev() {
        this.slider.slickPrev();
      }
  render() {
    const t = this.props.t;
    const settings = {
        slidesToShow: 3,
        slidesToScroll: 1,
        fade: false,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 4000,
        arrows: false,
        dots: false,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        responsive: [
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 2,
                },
            },
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 1,
                },
            },
        ],
    };
    return (
        <section className="feature-room-section pt-155 pb-115">
            <div className="container">
            <div className="row align-items-center">
                <div className="col-lg-6 col-md-8 col-sm-7">
                <div className="section-title">
                    <span className="title-tag">{t('take.a.look')}</span>
                    <h2>{t('room.plr')}</h2>
                </div>
                </div>
                <div className="col-lg-6 col-md-4 col-sm-5 d-none d-sm-block">
                    <div className="feature-room-arrow arrow-style text-right">
                        <SamplePrevArrow onClick={this.prev} />
                        <SampleNextArrow onClick={this.next} />
                    </div>
                </div>
            </div>
            {/* Room Looop */}
            <Slider className="row room-gird-loop mt-80 feature-room-slider" ref={slider => (this.slider = slider)} {...settings}>
            {rooms.map((item, i) => (
                <div key={i} className="col-lg-12">
                <div className="room-box">
                    <div className="room-img-wrap">
                    <div className="room-img" style={{backgroundImage: "url("+ item.photo + ")"}} />
                    </div>
                    <div className="room-desc">
                    <ul className="icons">
                        <li><i className="fal fa-bed" /></li>
                        <li><i className="fal fa-wifi" /></li>
                        <li><i className="fal fa-car" /></li>
                        <li><i className="fal fa-coffee" /></li>
                        <li><i className="fal fa-concierge-bell" /></li>
                        <li><i className="fal fa-compress-arrows-alt" /></li>
                    </ul>
                    <h4 className="title"><Link to={item.url}>{item.title}</Link></h4>
                    <p>{t(item.desc)}</p>
                    <span className="price">{item.price}</span>
                    <Link to={item.url} className="book-btn">{t(item.book)}</Link>
                    </div>
                </div>
                </div>
            ))}
            </Slider>
            </div>
        </section>
    );
  }
}

export default Featureroom;
