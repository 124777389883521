import React, { Component } from 'react'

class CookiePolicyEn extends Component {
    render() {
        const lang = this.props.lang;

        switch (lang) {
            case 'en':
                return(
                    <div className="text-justify">
                        <p><strong>What Are Cookies</strong></p>
        
                        <p>As is common practice with almost all professional websites this site uses cookies, which are tiny files that are downloaded to your computer, to improve your experience. This page describes what information they gather, how we use it and why we sometimes need to store these cookies. We will also share how you can prevent these cookies from being stored however this may downgrade or 'break' certain elements of the sites functionality.</p>
        
                        <p><strong>How We Use Cookies</strong></p>
        
                        <p>We use cookies for a variety of reasons detailed below. Unfortunately in most cases there are no industry standard options for disabling cookies without completely disabling the functionality and features they add to this site. It is recommended that you leave on all cookies if you are not sure whether you need them or not in case they are used to provide a service that you use.</p>
        
                        <p><strong>Disabling Cookies</strong></p>
        
                        <p>You can prevent the setting of cookies by adjusting the settings on your browser (see your browser Help for how to do this). Be aware that disabling cookies will affect the functionality of this and many other websites that you visit. Disabling cookies will usually result in also disabling certain functionality and features of the this site. Therefore it is recommended that you do not disable cookies.</p>
                        <p><strong>The Cookies We Set</strong></p>
        
                        <ul>
                            <li>
                                <p>Email newsletters related cookies</p>
                                <p>This site offers newsletter or email subscription services and cookies may be used to remember if you are already registered and whether to show certain notifications which might only be valid to subscribed/unsubscribed users.</p>
                            </li>
                            <li>
                                <p>Orders processing related cookies</p>
                                <p>This site offers e-commerce or payment facilities and some cookies are essential to ensure that your order is remembered between pages so that we can process it properly.</p>
                            </li>
                            <li>
                                <p>Forms related cookies</p>
                                <p>When you submit data to through a form such as those found on contact pages or comment forms cookies may be set to remember your user details for future correspondence.</p>
                            </li>
                            <li>
                                <p>Site preferences cookies</p>
                                <p>In order to provide you with a great experience on this site we provide the functionality to set your preferences for how this site runs when you use it. In order to remember your preferences we need to set cookies so that this information can be called whenever you interact with a page is affected by your preferences.</p>
                            </li>
                        </ul>
        
                        <p><strong>Third Party Cookies</strong></p>
        
                        <p>In some special cases we also use cookies provided by trusted third parties. The following section details which third party cookies you might encounter through this site.</p>
        
                        <p><strong>More Information</strong></p>
        
                        <p>Hopefully that has clarified things for you and as was previously mentioned if there is something that you aren't sure whether you need or not it's usually safer to leave cookies enabled in case it does interact with one of the features you use on our site.</p>
        
                        <p>However if you are still looking for more information then you can contact us through one of our preferred contact methods:</p>
        
                        <ul>
                            <li>Email: info@mrbeyazbutikhotel.com</li>
                        </ul>
                        </div>
                )       
            case 'fr':
                return(
                    <div className = "text-justify">
                        <p> <strong> Que sont les cookies </strong> </p>
        
                        <p> Comme c'est la pratique courante avec presque tous les sites Web professionnels, ce site utilise des cookies, qui sont de petits fichiers téléchargés sur votre ordinateur, pour améliorer votre expérience. Cette page décrit les informations qu'ils collectent, comment nous les utilisons et pourquoi nous avons parfois besoin de stocker ces cookies. Nous partagerons également comment vous pouvez empêcher ces cookies d'être stockés, mais cela peut déclasser ou «casser» certains éléments de la fonctionnalité du site. </p>
        
                        <p> <strong> Comment nous utilisons les cookies </strong> </p>
        
                        <p> Nous utilisons des cookies pour diverses raisons détaillées ci-dessous. Malheureusement, dans la plupart des cas, il n'existe pas d'options standard pour désactiver les cookies sans désactiver complètement les fonctionnalités et fonctionnalités qu'ils ajoutent à ce site. Il est recommandé de laisser tous les cookies si vous ne savez pas si vous en avez besoin ou non au cas où ils seraient utilisés pour fournir un service que vous utilisez. </p>
        
                        <p> <strong> Désactivation des cookies </strong> </p>
        
                        <p> Vous pouvez empêcher l'installation de cookies en ajustant les paramètres de votre navigateur (consultez l'aide de votre navigateur pour savoir comment procéder). Sachez que la désactivation des cookies affectera la fonctionnalité de ce site Web et de nombreux autres sites Web que vous visitez. La désactivation des cookies entraîne généralement la désactivation de certaines fonctionnalités et fonctionnalités de ce site. Par conséquent, il est recommandé de ne pas désactiver les cookies. </p>
                        <p> <strong> Les cookies que nous définissons </strong> </p>
        
                        <ul>
                            <li>
                                <p> Cookies liés aux newsletters par e-mail </p>
                                <p> Ce site propose des services d'abonnement à la newsletter ou par e-mail et les cookies peuvent être utilisés pour se souvenir si vous êtes déjà inscrit et s'il faut afficher certaines notifications qui pourraient ne s'appliquer qu'aux utilisateurs abonnés / désabonnés. </p>
                            </li>
                            <li>
                                <p> Les commandes traitant les cookies liés </p>
                                <p> Ce site propose des services de commerce électronique ou de paiement et certains cookies sont essentiels pour garantir que votre commande est mémorisée entre les pages afin que nous puissions la traiter correctement. </p>
                            </li>
                            <li>
                                <p> Cookies liés aux formulaires </p>
                                <p> Lorsque vous soumettez des données via un formulaire tel que ceux trouvés sur les pages de contact ou les formulaires de commentaires, des cookies peuvent être configurés pour mémoriser vos informations d'utilisateur pour une correspondance future. </p>
                            </li>
                            <li>
                                <p> Cookies de préférences du site </p>
                                <p> Afin de vous offrir une excellente expérience sur ce site, nous fournissons la fonctionnalité pour définir vos préférences sur la façon dont ce site fonctionne lorsque vous l'utilisez. Afin de nous souvenir de vos préférences, nous devons définir des cookies afin que ces informations puissent être appelées chaque fois que vous interagissez avec une page est affectée par vos préférences. </p>
                            </li>
                        </ul>
        
                        <p> <strong> Cookies tiers </strong> </p>
        
                        <p> Dans certains cas particuliers, nous utilisons également des cookies fournis par des tiers de confiance. La section suivante détaille les cookies tiers que vous pourriez rencontrer via ce site. </p>
        
                        <p> <strong> Plus d'informations </strong> </p>
        
                        <p> J'espère que cela a clarifié les choses pour vous et, comme cela a été mentionné précédemment, s'il y a quelque chose dont vous ne savez pas si vous avez besoin ou non, il est généralement plus sûr de laisser les cookies activés au cas où ils interagiraient avec l'une des fonctionnalités que vous utilisez. notre site. </p>
        
                        <p> Cependant, si vous cherchez toujours plus d'informations, vous pouvez nous contacter via l'une de nos méthodes de contact préférées: </p>
        
                        <ul>
                            <li> E-mail: info@mrbeyazbutikhotel.com </li>
                        </ul>
                        </div>
                ) 
            case 'ru':
                return (
                    <div className = "text-justify">
                        <p> <strong> Что такое файлы cookie </strong> </p>
        
                        <p> Как это принято почти на всех профессиональных веб-сайтах, этот сайт использует файлы cookie, которые представляют собой крошечные файлы, которые загружаются на ваш компьютер для улучшения вашего опыта. На этой странице описывается, какую информацию они собирают, как мы ее используем и почему нам иногда необходимо хранить эти файлы cookie. Мы также расскажем, как вы можете предотвратить сохранение этих файлов cookie, однако это может привести к ухудшению или «поломке» определенных элементов функциональности сайтов. </p>
        
                        <p> <strong> Как мы используем файлы cookie </strong> </p>
        
                        <p> Мы используем файлы cookie по разным причинам, подробно описанным ниже. К сожалению, в большинстве случаев нет стандартных отраслевых опций для отключения файлов cookie без полного отключения функций и функций, которые они добавляют на этот сайт. Рекомендуется оставить все файлы cookie, если вы не уверены, нужны они вам или нет, в случае, если они используются для предоставления услуги, которую вы используете. </p>
        
                        <p> <strong> Отключение файлов cookie </strong> </p>
        
                        <p> Вы можете предотвратить установку файлов cookie, изменив настройки своего браузера (см. справку своего браузера, чтобы узнать, как это сделать). Имейте в виду, что отключение файлов cookie повлияет на функциональность этого и многих других веб-сайтов, которые вы посещаете. Отключение файлов cookie обычно приводит к отключению определенных функций и возможностей этого сайта. Поэтому рекомендуется не отключать файлы cookie. </p>
                        <p> <strong> Мы устанавливаем файлы cookie </strong> </p>
        
                        <ul>
                            <li>
                                <p> Файлы cookie, связанные с информационными бюллетенями </p>
                                <p> Этот сайт предлагает услуги рассылки новостей или подписки по электронной почте, а файлы cookie могут использоваться, чтобы помнить, зарегистрированы ли вы уже, и показывать ли определенные уведомления, которые могут быть действительны только для подписанных / неподписанных пользователей. </p>
                            </li>
                            <li>
                                <p> Заказы, связанные с обработкой файлов cookie </p>
                                <p> Этот сайт предлагает средства электронной коммерции или оплаты, и некоторые файлы cookie необходимы для того, чтобы ваш заказ запоминался между страницами, чтобы мы могли его правильно обработать. </p>
                            </li>
                            <li>
                                <p> Формирует связанные файлы cookie </p>
                                <p> Когда вы отправляете данные через форму, например, на страницах контактов или в формах комментариев, файлы cookie могут быть настроены для запоминания ваших данных пользователя для будущей переписки. </p>
                            </li>
                            <li>
                                <p> Файлы cookie настроек сайта </p>
                                <p> Чтобы обеспечить вам удобство работы с этим сайтом, мы предоставляем функциональные возможности для настройки ваших предпочтений в отношении того, как этот сайт будет работать, когда вы его используете. Чтобы запомнить ваши предпочтения, нам необходимо установить файлы cookie, чтобы эта информация могла вызываться всякий раз, когда вы взаимодействуете со страницей, в зависимости от ваших предпочтений. </p>
                            </li>
                        </ul>
        
                        <p> <strong> Сторонние файлы cookie </strong> </p>
        
                        <p> В некоторых особых случаях мы также используем файлы cookie, предоставленные доверенными третьими сторонами. В следующем разделе подробно описаны файлы cookie третьих сторон, с которыми вы можете столкнуться на этом сайте. </p>
        
                        <p> <strong> Дополнительная информация </strong> </p>
        
                        <p> Надеюсь, это прояснило вам ситуацию, и, как уже упоминалось ранее, если есть что-то, в чем вы не уверены, нужно вам или нет, обычно безопаснее оставить файлы cookie включенными, если они действительно взаимодействуют с одной из функций, которые вы используете наш сайт. </p>
        
                        <p> Однако, если вы все еще ищете дополнительную информацию, вы можете связаться с нами одним из предпочтительных способов связи: </p>
        
                        <ul>
                            <li> Электронная почта: info@mrbeyazbutikhotel.com </li>
                        </ul>
                        </div>
                )
            default:
                return (
                <div className = "text-justify">
                <p> <strong> Çerezler Nedir </strong> </p>

                <p> Neredeyse tüm profesyonel web sitelerinde yaygın bir uygulama olduğu gibi, bu site, deneyiminizi iyileştirmek için bilgisayarınıza indirilen küçük dosyalar olan tanımlama bilgilerini kullanır. Bu sayfa, hangi bilgileri topladıklarını, bunları nasıl kullandığımızı ve bazen bu çerezleri neden saklamamız gerektiğini açıklamaktadır. Ayrıca, bu çerezlerin saklanmasını nasıl önleyebileceğinizi de paylaşacağız, ancak bu, sitelerin işlevselliğinin belirli öğelerini eski sürüme geçirebilir veya 'bozabilir'. </p>

                <p> <strong> Çerezleri Nasıl Kullanıyoruz? </strong> </p>

                <p> Çerezleri aşağıda ayrıntıları verilen çeşitli nedenlerle kullanıyoruz. Maalesef çoğu durumda, bu siteye ekledikleri işlevselliği ve özellikleri tamamen devre dışı bırakmadan çerezleri devre dışı bırakmak için endüstri standardı seçenekler yoktur. Kullandığınız bir hizmeti sağlamak için kullanılmaları ihtimaline karşı, ihtiyacınız olup olmadığından emin değilseniz tüm çerezleri bırakmanız önerilir. </p>

                <p> <strong> Çerezleri Devre Dışı Bırakma </strong> </p>

                <p> Tarayıcınızın ayarlarını değiştirerek çerezlerin ayarlanmasını önleyebilirsiniz (bunu nasıl yapacağınız için tarayıcınızın Yardımına bakın). Çerezleri devre dışı bırakmanın bu ve ziyaret ettiğiniz diğer birçok web sitesinin işlevselliğini etkileyeceğini unutmayın. Çerezleri devre dışı bırakmak, genellikle bu sitenin belirli işlevlerinin ve özelliklerinin de devre dışı bırakılmasına neden olur. Bu nedenle, çerezleri devre dışı bırakmamanız önerilir. </p>
                <p> <strong> Oluşturduğumuz Çerezler </strong> </p>

                <ul>
                    <li>
                        <p> E-posta bültenleri ile ilgili çerezler </p>
                        <p> Bu site haber bülteni veya e-posta abonelik hizmetleri sunar ve önceden kayıtlı olup olmadığınızı ve yalnızca abone olan / abone olmayan kullanıcılar için geçerli olabilecek belirli bildirimlerin gösterilip gösterilmeyeceğini hatırlamak için çerezler kullanılabilir. </p>
                    </li>
                    <li>
                        <p> İlgili çerezleri işleyen siparişler </p>
                        <p> Bu site e-ticaret veya ödeme olanakları sunar ve siparişinizin doğru şekilde işleyebilmemiz için sayfalar arasında hatırlanmasını sağlamak için bazı çerezler gereklidir. </p>
                    </li>
                    <li>
                        <p> Formlarla ilgili çerezler </p>
                        <p> İletişim sayfalarında veya yorum formlarında bulunanlar gibi bir form aracılığıyla veri gönderdiğinizde, gelecekteki yazışmalar için kullanıcı bilgilerinizi hatırlamak üzere çerezler ayarlanabilir. </p>
                    </li>
                    <li>
                        <p> Site tercihleri ​​çerezleri </p>
                        <p> Size bu sitede harika bir deneyim sağlamak için, kullandığınızda bu sitenin nasıl çalışacağına ilişkin tercihlerinizi belirleme işlevini sunuyoruz. Tercihlerinizi hatırlamak için, tercihlerinizden etkilenen bir sayfayla her etkileşimde bulunduğunuzda bu bilgilerin çağrılabilmesi için tanımlama bilgileri ayarlamamız gerekir. </p>
                    </li>
                </ul>

                <p> <strong> Üçüncü Taraf Çerezleri </strong> </p>

                <p> Bazı özel durumlarda, güvenilir üçüncü taraflarca sağlanan çerezleri de kullanırız. Aşağıdaki bölüm, bu site aracılığıyla karşılaşabileceğiniz üçüncü taraf çerezlerini ayrıntılarıyla anlatmaktadır. </p>

                <p> <strong> Daha Fazla Bilgi </strong> </p>

                <p> Umarız bu, sizin için bazı şeyleri açıklığa kavuşturmuştur ve daha önce de belirtildiği gibi, ihtiyacınız olup olmadığından emin olmadığınız bir şey varsa, kullandığınız özelliklerden biriyle etkileşime girmesi durumunda çerezleri etkin bırakmanın genellikle daha güvenli olduğunu sitemiz. </p>

                <p> Ancak yine de daha fazla bilgi arıyorsanız, tercih ettiğiniz iletişim yöntemlerimizden birini kullanarak bizimle iletişime geçebilirsiniz: </p>

                    <ul>
                        <li> E-posta: info@mrbeyazbutikhotel.com </li>
                    </ul>
                </div>)
        }
    }
}

export default CookiePolicyEn;