import React, { Component } from 'react';
// import CountUp from 'react-countup';
import {social} from '../../constants'

class Counter extends Component {
  render() {
    return (
        <section className="counter-section bg-black pt-100 pb-50">
            <div className="container">
                <div className="row">
                    {social.length ? social.map((el, i) => (
                        <div className="col-lg-4 col-md-6 col-6 text-center" key={i}>
                            <div className="counter-box">
                                <div className="icon hotel-text-primary">
                                    <i className={el.icon + ' fa-3x'}></i>
                                </div>
                                <a className="title" href={el.link} rel="noopener noreferrer external nofollow" target="_blank">{el.text}</a>
                            </div>
                        </div>
                    )) : null}
                </div>
            </div>
        </section>
    );
  }
}

export default Counter;
