import React, { Component } from 'react'

class AGEWBTECHS extends Component {
    render() {
        return (
            <p className="copyright-text">
                <a href="https://agewebtechs.com/" target="blank" rel="noopener noreferrer external nofollow">
                    <img src="/assets/img/agewt-white.png" alt="agewebtechs.com" height="50"></img>
                </a>
            </p>
        )
    }
}

export default AGEWBTECHS; 