import React, {useEffect} from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useLocation,
  withRouter
} from 'react-router-dom'

import Preloader from './components/layouts/Preloader';
// import Home from './components/pages/Home';
import Hometwo from './components/pages/Hometwo';
// import Homethree from './components/pages/Homethree';
import About from './components/pages/About';
import CookiePolicy from './components/pages/CookiePolicy';
import KVKK from './components/pages/KVKK';
import Gallery from './components/pages/Gallery';
import Menu from './components/pages/Menu';
import Offers from './components/pages/Offers';
import Restaurant from './components/pages/Restaurant';
import Blog from './components/pages/Blog';
import Blogdetails from './components/pages/Blogdetails';
import Places from './components/pages/Places';
import Placesdetails from './components/pages/Placesdetails';
// import Roomgrid from './components/pages/Roomgrid';
import Roomlist from './components/pages/Roomlist';
import RoomDetails from './components/pages/RoomDetails';
import Contact from './components/pages/Contact';
import i18n from './i18n'
import AcceptCookies from './components/layouts/AcceptCookies';

function _ScrollToTop(props) {
  const { pathname } = useLocation();
  useEffect(() => {
      window.scrollTo(0, 0);
  }, [pathname]);
  return props.children
}
const ScrollToTop = withRouter(_ScrollToTop)

function App() {
  const lang = localStorage.getItem('lang') ?  localStorage.getItem('lang') : 'tr';
  localStorage.setItem('lang', lang);
  i18n.changeLanguage(lang);
  
  return (
      <Router>
        { <Preloader />}
        {<AcceptCookies />}
        <Switch>
          <ScrollToTop>
            {/* <Route exact path='/' component={Home} /> */}
            <Route exact path='/' component={Hometwo} />
            {/* <Route path='/home-three' component={Homethree} /> */}
            <Route path='/about' component={About} />
            <Route path='/gallery' component={Gallery} />
            <Route path='/menu' component={Menu} />
            <Route path='/offers' component={Offers} />
            <Route path='/restaurant' component={Restaurant} />
            <Route path='/news' component={Blog} />
            <Route path='/news-details' component={Blogdetails} />
            <Route path='/places' component={Places} />
            <Route path='/places-details' component={Placesdetails} />
            {/* <Route path='/room-grid' component={Roomgrid} /> */}
            <Route path='/room-list' component={Roomlist} />
            <Route path='/contact' component={Contact} />
            {/* Odalar */}
            <Route path='/big-double-room' component={RoomDetails} />
            <Route path='/family-room' component={RoomDetails} />
            <Route path='/single-room' component={RoomDetails} />
            <Route path='/small-double-room' component={RoomDetails} />
            <Route path='/twin-room' component={RoomDetails} />
            <Route path='/cookie-policy' component={CookiePolicy} />
            <Route path='/privacy-policy' component={KVKK} />
          </ScrollToTop>
        </Switch>
      </Router>
  );
}

export default App;
