import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import i18n from 'i18next'
import {social} from '../constants'
import AGEWBTECHS from './agewebtechs';

class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {
       redText: false
    };
  }
  componentDidMount() {
    window.addEventListener('scroll', () => {
      this.setState({
          isTop: window.scrollY > 300
      });
  }, false);
  } 
  scrollToTop() {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  }
  render() {
    const className = this.state.isTop ? 'active' : '';
    const t = function(obj) {
      return i18n.t(obj);
    }
    return (
      <div>
        {/*====== Back to Top ======*/}
        
        <Link to="#" className={`back-to-top ${className}`} id="backToTop" onClick={() => this.scrollToTop()}>
          <i className="fal fa-angle-double-up" />
        </Link>
        
        {/*====== FOOTER PART START ======*/}
        <footer>
          <div className="footer-subscibe-area pt-120 pb-120">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-lg-8">
                  <div className="subscribe-text text-center">
                    <div className="footer-logo mb-45">
                      <img src="assets/img/logo-white.png" alt="" />
                    </div>
                    <p>
                    {t('footer.description')} 
                    </p>
                    {/* <form action="#" className="subscribe-form mt-50">
                      <input type="email" placeholder="Enter your email address" />
                      <button type="submit">subscribe</button>
                    </form> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="copyright-area pt-20 pb-20">
            <div className="container">
              <div className="row align-items-center">
                <div className="col-md-5 order-2 order-md-1">
                  <AGEWBTECHS />
                </div>
                <div className="col-md-7 order-1 order-md-2">
                  <div className="social-links">
                    {
                      social.length ? social.map((el, i) => (
                        <a key={i} href={el.link} target="_blank" rel="noopener noreferrer"><i className={el.icon} /></a>
                      )) : null
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </footer>
        {/*====== FOOTER PART END ======*/}
      </div>
    );
  }
}

export default Footer;
