import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import {rooms} from '../../constants'

class Offers extends Component {
  render() {
    const t = this.props.t;
    return (
      <section className="offers-area pt-120 pb-60">
        <div className="container">
          <div className="offer-boxes-loop">
          {rooms.map((item, i) => (
            <div key={i} className="offer-box">
              <img src={item.photo.replace('.jpg','_large.jpg')} alt="" className="img-fluid" width="100%" />
              <div className="offer-desc">
                <div className="title-wrap">
                  <div className="title">
                    <span className="room-cat">{item.cat}</span>
                    <h2>{item.title}</h2>
                  </div>
                  <div className="price">
                  {item.price}<span>/{t('booking.night')}</span>
                  </div>
                </div>
                <div className="row justify-content-between">
                  <div className="col-lg-6">
                    <div className="offer-text">
                      <p>
                      {t(item.desc)}
                      </p>
                      <Link to={item.url} className="main-btn btn-filled">{t(item.book)}</Link>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="offer-feature">
                      <ul>
                        <li><i className="fal fa-wind" />{t('room.amenities.ac')}</li>
                        <li><i className="fal fa-wifi" />{t('room.amenities.wifi')}</li>
                        <li><i className="fal fa-key" />{t('room.amenities.locker')}</li>
                        <li><i className="fal fa-concierge-bell" />{t('room.amenities.breakfast')}</li>
                        <li><i className="fal fa-lock" />{t('room.amenities.security')}</li>
                        <li><i className="fal fa-broom" />{t('room.amenities.cleaning')}</li>
                        <li><i className="fal fa-shower" />{t('room.amenities.shower')}</li>
                        <li><i className="fal fa-headphones-alt" />{t('room.amenities.support')}</li>
                        <li><i className="fal fa-shopping-basket" />{t('room.amenities.grocery')}</li>
                        <li><i className="fal fa-bed" />{t('room.amenities.bed')}</li>
                        <li><i className="fal fa-users" />{t('room.amenities.expert')}</li>
                        <li><i className="fal fa-shopping-cart" />{t('room.amenities.shop')}</li>
                        <li><i className="fal fa-bus" />{t('room.amenities.towels')}</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
          </div>
        </div>
      </section>
    );
  }
}

export default Offers;
