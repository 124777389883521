import React, { Component } from 'react'
import Headertwo from '../layouts/Headertwo';
import Footertwo from '../layouts/Footertwo';
import Mainbanner from '../sections/homepage-two/Banner';
import Bookingform from '../sections/homepage-two/Bookingform';
import Textblock from '../sections/homepage-two/Textblock';
import Corefeature from '../sections/homepage-two/Corefeature';
import Featureroom from '../sections/homepage-two/Featureroom';
import Counter from '../sections/homepage-two/Counter';
// import Testimonial from '../sections/homepage-one/Testimonials';
// import Videowrap from '../sections/homepage-two/Videowrap';
// import News from '../sections/homepage-one/News';
import GallerySlider from '../sections/homepage-two/GallerySlider';
import i18n from 'i18next'

class Hometwo extends Component {
  render() {
    const t = o => {
      return i18n.t(o);
    }

    return (
      <div>
        <Headertwo />
        <Mainbanner t={t} />
        <Bookingform t={t} />
        <Textblock t={t} />
        <Corefeature t={t} />
        <Featureroom t={t} />
        {/* <Videowrap t={t} /> */}
        <Counter t={t} />
        {/* <News t={t} /> */}
        {/* <Testimonial/> */}
        <GallerySlider t={t} />
        <Footertwo t={t} />
      </div>
    );
  }
}

export default Hometwo;
