export const rooms = [
  {
      photo: 'assets/img/room/01.jpg',
      title: 'Big Double Room',
      url: '/big-double-room',
      price: '',
      book: 'booking.book',
      desc: 'room.big.desc',
      gallery: require.context('../assets/img/room/big-double-room', false, /\.(png|jpe?g|svg)$/),
      related: [3,1]
  },
  {
      photo: 'assets/img/room/02.jpg',
      title: 'Family Room',
      url: '/family-room',
      price: '',
      book: 'booking.book',
      desc: 'room.family.desc',
      gallery: require.context('../assets/img/room/family-room', false, /\.(png|jpe?g|svg)$/),
      related: [0,3]
  },
  {
      photo: 'assets/img/room/03.jpg',
      title: 'Single Room',
      url: '/single-room',
      price: '',
      book: 'booking.book',
      desc: 'room.single.desc',
      gallery: require.context('../assets/img/room/single-room', false, /\.(png|jpe?g|svg)$/),
      related: [4]
  },
  {
      photo: 'assets/img/room/04.jpg',
      title: 'Small Double Room',
      url: '/small-double-room',
      price: '',
      book: 'booking.book',
      desc: 'room.small.desc',
      gallery: require.context('../assets/img/room/small-double-room', false, /\.(png|jpe?g|svg)$/),
      related: [0,1]
  },
  {
      photo: 'assets/img/room/05.jpg',
      title: 'Twin Room',
      url: '/twin-room',
      price: '',
      book: 'booking.book',
      desc: 'room.twin.desc',
      gallery: require.context('../assets/img/room/twin-room', false, /\.(png|jpe?g|svg)$/),
      related: [2]
  }
];

const RoomSubmenu = [];

rooms.map((room, i) => {
  let el = {
    id: 2+i,
    link: room.url,
    linkText: room.title
  }

  return RoomSubmenu.push(el);
})

RoomSubmenu.push({
  id: 29,
  link: '/room-list',
  linkText: 'room.all'
})


export const navigationmenu = [
    {
        id: 1,
        linkText: 'header.home',
        link: '/',
        child: false,
        // submenu: [
        //     {
        //         id: 11,
        //         link: '/',
        //         linkText: 'Home 1'
        //     },
        //     {
        //         id: 12,
        //         new: true,
        //         link: '/home-two',
        //         linkText: 'Home 2'
        //     },
        //     {
        //         id: 13,
        //         link: '/home-three',
        //         linkText: 'Home 3'
        //     },
        // ]
        
        
    },
    {
      
      id: 2,
      linkText: 'header.rooms',
      link:'#',
      child: true,
      submenu: RoomSubmenu
  },
  // {
  //   id: 3,
  //   linkText: 'News',
  //   child: true,
  //   submenu:[
  //     {
  //       id: 31,
  //       link:'/news',
  //       linkText:'News',
  //     },
  //     {
  //       id: 32,
  //       link:'/news-details',
  //       linkText:'News Details',
  //     },
  //   ]
    
  // },
    {
      id: 3,
      link: '#',
      linkText: 'header.about',
      child: true,
      submenu: [
        {
          id: 31,
          link: '/about',
          linkText: 'about.us',
          child: false,
        },
        {
          id: 32,
          link: '/restaurant',
          linkText: 'restaurant.name',
          child: false,
        },
        {
          id: 33,
          link: '/contact',
          linkText: 'header.contact',
          child: false,
        },
        {
          id: 34,
          link: '/places',
          linkText: 'sidemenu.places',
          child: false,
        }
      ]
    },
    {
      id: 4,
      link: '/gallery',
      linkText: 'sidemenu.gallery',
      child: false,
    },
    {
      id: 7,
      link: '#',
      icon: 'fas fa-globe',
      linkText: 'header.lang',
      child: true,
      submenu:[
        {
          id: 71,
          link:'#',
          lang: 'tr',
          linkText:'Türkçe',
          hasClick: true
        },
        {
          id: 72,
          link:'#',
          lang: 'en',
          linkText:'English',
          hasClick: true
        },
        {
          id: 73,
          link:'#',
          lang: 'fr',
          linkText:'Français',
          hasClick: true
        },
        {
          id: 74,
          link:'#',
          lang: 'ru',
          linkText:'Россия',
          hasClick: true
        },
      ]
    }
  ]

export const info = {
  phone: '+90 (212) 225 44 44',
  phoneTo: '902122254444',
  email: 'info@mrbeyazbutikhotel.com',
  address: 'MEŞRUTİYET MAH. SUNA KIRAÇ SOK. NO:4 NİŞANTAŞI OSMANBEY / İSTANBUL'
}

export const places = [{
  time: '20',
  photo: 'https://upload.wikimedia.org/wikipedia/commons/0/09/Taksim_Square.jpg'
},{
  time: '20',
  photo: 'https://upload.wikimedia.org/wikipedia/commons/e/ed/Dolmabahçe_Palace.JPG'
},{
  time: '25',
  photo: 'https://upload.wikimedia.org/wikipedia/commons/1/19/Galata_Bridge.JPG'
},{
  time: '25',
  photo: 'https://upload.wikimedia.org/wikipedia/commons/8/82/Istanbul_2009_Yeni_Camii.JPG'
},{
  time: '12',
  photo: 'https://upload.wikimedia.org/wikipedia/commons/2/2f/Cevahir_Shopping_Mall_in_Istanbul.jpg'
},{
  time: '15',
  photo: 'https://upload.wikimedia.org/wikipedia/commons/thumb/e/e4/Trump_Towers%2C_%C5%9Ei%C5%9Fli_-_residental_tower.jpg/270px-Trump_Towers%2C_%C5%9Ei%C5%9Fli_-_residental_tower.jpg'
},{
  time: '20',
  photo: 'https://upload.wikimedia.org/wikipedia/commons/thumb/f/fb/Be%C5%9Fikta%C5%9F_J.K._vs_Bursaspor_11_April_2016_%285%29.jpg/1920px-Be%C5%9Fikta%C5%9F_J.K._vs_Bursaspor_11_April_2016_%285%29.jpg'
},{
  time: '25',
  photo: 'https://upload.wikimedia.org/wikipedia/commons/f/fd/Istanbul_-_Avinguda_d%27Istikl%C3%A2l.JPG'
},{
  time: '10',
  photo: 'https://upload.wikimedia.org/wikipedia/commons/7/7b/Ma%C3%A7ka_Gondola_03.JPG'
},{
  time: '20',
  photo: 'https://upload.wikimedia.org/wikipedia/commons/5/59/Kapali_Carsi-Grand_Bazar-Istanbul-Sep08.jpg'
},{
  time: '20',
  photo: 'https://upload.wikimedia.org/wikipedia/commons/1/13/%C4%B0stinye_Park.jpg'
 },{
   time: '10',
   photo: 'https://upload.wikimedia.org/wikipedia/commons/3/3e/Zorlu_Center_%C4%B0stanbul%2C_p5.jpg'
 }]

export const core_flaticons = ['car','location-pin','team','wifi','serving-dish','hotel']

export const social = [
  {
    icon: 'fab fa-instagram',
    link: 'https://www.instagram.com/mrbeyazbutikhotel/',
    text: 'mrbeyazbutikhotel'
  },
  {
    icon: 'fab fa-facebook-f',
    link: 'https://www.facebook.com/beyaz.butikotel',
    text: 'beyaz.butikotel'
  },
  {
    icon: 'fab fa-whatsapp',
    link: 'https://wa.me/902122254444',
    text: '+90 (212) 225 44 44'
  }
]

export default {}