import React, { Component } from 'react';
import { Tab, Nav } from 'react-bootstrap';
import i18n from 'i18next'

class Restaurant extends Component {
  render() { 
    const t = o => {
      return i18n.t(o);
    }

    const icons = ['boiled','pizza','coffee','cake','cookie','cocktail'];
    
    return (
      <section className="restaurant-tab-area pt-120 pb-90">
        <Tab.Container defaultActiveKey="menu0">
          <div className="container">
          <Nav variant="pills" className="restaurant-rood-list row justify-content-center nav nav-pills mb-30">
              {
                icons.map((el, i) => (
                  <Nav.Item className="nav-item col-lg-2 col-md-3 col-sm-4 col-6" key={el}>
                    <Nav.Link className="nav-link" eventKey={'menu'+i}>
                      <i className={'flaticon-'+el} />
                      <span className="title">{t('restaurant.menu.m'+i)}</span>
                    </Nav.Link>
                  </Nav.Item>
                ))
              }
          </Nav>
            {/* tab content */}
            <Tab.Content id="restaurant-tabContent">
              {
                icons.map((el, i) => (
                  <Tab.Pane eventKey={'menu'+i} key={el + 'pane'}>
                    <div className="row">
                      <div className="col-lg-6 offset-lg-3">
                        <img src={'/assets/img/restu/'+i+'.jpg'} alt={'menu' + i} className="img-fluit rounded" />
                      </div>
                    </div>
                  </Tab.Pane>
                ))
              }
            </Tab.Content>
          </div>
        </Tab.Container>
        </section>
        
    );
  }
}

export default Restaurant;
