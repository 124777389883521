import React, { Component } from 'react'
import { Link } from 'react-router-dom'

class AcceptCookies extends Component {
    render() {
        const CookiesMenu = localStorage.getItem('CookiesMenu');

        const CloseCookiesMenu = () => {
            localStorage.setItem('CookiesMenu', true);
            document.getElementById('CookiesMenu').remove();
        }

        return (
            <div>
                {
                    !CookiesMenu ? <div className="container-fluid fixed-bottom bg-dark" id="CookiesMenu">
                    <div className="container d-flex flex-nowrap py-3">
                        <div className="flex-grow-1 align-self-center">
                            Sizlere daha iyi hizmet sunabilmek için web sitemizde çerezler kullanılmaktadır. Daha fazla bilgi için <Link to="/cookie-policy">tıklayınız</Link>.
                        </div>
                        <div className="px-2">
                            <Link onClick={CloseCookiesMenu} to="#" className="main-btn btn-filled shadow">Okudum / Kapat</Link>
                        </div>
                    </div>
                </div> : null
                }
            </div>
        );
    }
}

export default AcceptCookies;