import React, { Component } from 'react';
import i18n from 'i18next'
import {places} from '../../constants'


class Placesbox extends Component {
  render() {
    const t = o => {
      return i18n.t(o);
    }

    return (
      <div className="places-boxes pt-115">
        {/* Title */}
        <div className="section-title text-center mb-50">
          <span className="title-tag">{ t('places.subtitle') }</span>
          <h2>{ t('places.title') }</h2>
        </div>
        <div className="row justify-content-center">
        {places.map((item, i) => (
          <div key={i} className="col-lg-3 col-md-4 col-sm-6 col-10">
            <div className="place-box mt-30">
              <div className="place-bg-wrap">
                <div className="place-bg" style={{backgroundImage: "url("+ item.photo + ")"}} />
              </div>
              <div className="desc">
              <h4><a href={'https://'+ i18n.language +'.wikipedia.org/wiki/' + t('places.p' + i)} rel="noopener noreferrer external nofollow" target="_blank">{t('places.p' + i).replace(/_/g,' ')}</a></h4>
                <span className="time">{item.time + ' ' + t('min')}</span>
              </div>
            </div>
          </div>
        ))}
        </div>
      </div>
    );
  }
}

export default Placesbox;
