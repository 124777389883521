import React, { Component } from 'react';
import i18n from 'i18next'
import DatePicker, { registerLocale, setDefaultLocale } from "react-datepicker";
import {tr, enUS, fr, ru} from 'date-fns/locale';

registerLocale('tr-TR', tr);
registerLocale('en-EN', enUS);
registerLocale('fr-FR', fr);
registerLocale('ru-RU', ru);

const today = new Date();

class Bookingform extends Component {
    constructor(props) {
        super(props);
        this.state = {
           checkIn: today,
           checkOut: this.nextDay(today)
        };
    
        this.setCheckIn = this.setCheckIn.bind(this);
        this.setCheckOut = this.setCheckOut.bind(this);
        this.nextDay = this.nextDay.bind(this);
      }
    
      nextDay(d){
        let n = new Date(d);
        return n.setDate(n.getDate() + 1);
      }
    
      setCheckIn(d){
        this.setState({
          checkIn: d,
          checkOut: this.nextDay(d)
        });
      }
    
      setCheckOut(d){
        this.setState({
          checkOut: d
        });
      }
    
  render() {
    const t = this.props.t;

    const getNumNights = () =>{
        var start = new Date(this.state.checkIn); 
        var end = new Date(this.state.checkOut);     
        var diff = end.getTime() - start.getTime();

        return parseInt(Math.round(diff / (1000 * 3600 * 24)));
    }


    let lang = i18n.language;
    setDefaultLocale(lang)
    lang  += "-" + lang.toUpperCase();

    return (
        <section className="booking-form">
            <div className="container">
            <div className="booking-form-inner">
                <form action={"https://mrbeyazbutikhotel.istbooking.com/"+ lang}>
                <input type="number" value="1" name="sr" id="sr" hidden readOnly />
                <div className="row align-items-end">
                    <div className="col-lg-3 col-md-6">
                        <div className="inputs-filed mt-30">
                            <label htmlFor="ci">{t('booking.checkin')}</label>
                            <div className="icon"><i className="fal fa-calendar-alt" /></div>
                            <DatePicker dateFormat="dd.MM.yyyy" selected={this.state.checkIn} minDate={today} onChange={date => this.setCheckIn(date)} name="ci" id="ci" locale={lang} />
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <div className="inputs-filed mt-30">
                            <label htmlFor="ci">{t('booking.checkout')}</label>
                            <div className="icon"><i className="fal fa-calendar-alt" /></div>
                            <DatePicker dateFormat="dd.MM.yyyy" selected={this.state.checkOut} minDate={this.nextDay(this.state.checkIn)} onChange={date => this.setCheckOut(date)} locale={lang} />
                            <input type="number" value={getNumNights()} name="ng"  hidden readOnly />
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <div className="inputs-filed mt-30">
                            <label htmlFor="ad">{t('booking.guest')}</label>
                            <div className="icon"><i className="fas fa-chevron-down" /></div>
                            <div className="nice-select">
                                <select name="ad" id="ad">
                                    <option value={1}>1</option>
                                    <option value={2}>2</option>
                                    <option value={3}>3</option>
                                    <option value={4}>4</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <div className="inputs-filed mt-30">
                            <button type="submit">{t('booking.checkav')}</button>
                        </div>
                    </div>
                </div>
                </form>
            </div>
            </div>
        </section>
        
    );
  }
}

export default Bookingform;
