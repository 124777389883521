import React, { Component } from 'react'
import Headerfour from '../layouts/Headerfour';
import Footer from '../layouts/Footer';
import { Link } from 'react-router-dom';
import ReactWOW from 'react-wow'
import i18n from 'i18next'


function importAll(r) {
  return r.keys().map(r);
}

const galleryPosts = importAll(require.context('../../assets/img/room', true, /\.(png|jpe?g|svg)$/));

class Gallery extends Component {
  render() {
    const t = obj => {
      return i18n.t(obj);
    }

    return (
      <div>
        <Headerfour />
        {/*====== BREADCRUMB PART START ======*/}
        <section className="breadcrumb-area" style={{backgroundImage: 'url(assets/img/bg/04.jpg)'}}>
          <div className="container">
            <div className="breadcrumb-text">
              <span>{t('room.subtitle')}</span>
              <h2 className="page-title">{t('sidemenu.gallery')}</h2>
              <ul className="breadcrumb-nav">
                <li><Link to="/">{t('header.home')}</Link></li>
                <li className="active">{t('sidemenu.gallery')}</li>
              </ul>
            </div>
          </div>
        </section>
        {/*====== BREADCRUMB PART END ======*/}
        {/*====== GALLERY START ======*/}
        <section className="gallery-wrappper pt-120 pb-120">
          <div className="container">
            <div className="gallery-loop">
            {galleryPosts.map((item, i) => (
              <ReactWOW key={i} animation="fadeInUp" data-wow-delay={i*0.1}>
              <div className="single-gallery-image">
                <Link to={item.split('.')[0].replace('static','assets/img') + '.' + item.split('.')[2]} target="_blank" className="popup-image">
                  <img src={item} alt="gallery-images" />
                </Link>
              </div>
              </ReactWOW>
              ))}
            </div>
          </div>
        </section>
        {/*====== GALLERY END ======*/}
        <Footer />
      </div>

    );
  }
}

export default Gallery;
