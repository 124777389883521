import i18n from 'i18next'
import detector from "i18next-browser-languagedetector";
import backend from "i18next-http-backend";
import { initReactI18next } from "react-i18next";
import tr from './locales/tr.json'
import en from './locales/en.json'
import fr from './locales/fr.json'
import ru from './locales/ru.json'

i18n
.use(detector)
.use(backend)
.use(initReactI18next)
.init({
    resources: {
        tr: {
            translation: tr
        },
        en: {
            translation: en
        },
        fr: {
            translation: fr
        },
        ru: {
            translation: ru
        }
    },
    lng: 'tr',
    fallbackLng: 'tr',
    saveMissing: true,
    interpolation: {
        escapeValue: false
    }
});

export default i18n;