import React, { Component } from 'react'

class CookiePolicyEn extends Component {
    render() {
        const lang = this.props.lang;

        switch (lang) {
            case 'en':
                return(
                    <div className="text-justify">
                        <p><strong>Privacy Policy</strong></p>

                        <p>At MR BEYAZ BOUTIQUE HOTEL, accessible from https://mrbeyazbutikhotel.com/, one of our main priorities is the privacy of our visitors. This Privacy Policy document contains types of information that is collected and recorded by MR BEYAZ BOUTIQUE HOTEL and how we use it.</p>

                        <p>If you have additional questions or require more information about our Privacy Policy, do not hesitate to contact us.</p>

                        <p>This Privacy Policy applies only to our online activities and is valid for visitors to our website with regards to the information that they shared and/or collect in MR BEYAZ BOUTIQUE HOTEL. </p>

                        <p><strong>Consent</strong></p>

                        <p>By using our website, you hereby consent to our Privacy Policy and agree to its terms.</p>

                        <p><strong>Information we collect</strong></p>

                        <p>The personal information that you are asked to provide, and the reasons why you are asked to provide it, will be made clear to you at the point we ask you to provide your personal information.</p>
                        <p>If you contact us directly, we may receive additional information about you such as your name, email address, phone number, the contents of the message and/or attachments you may send us, and any other information you may choose to provide.</p>
                        <p>When you register for an Account, we may ask for your contact information, including items such as name, company name, address, email address, and telephone number.</p>

                        <p><strong>How we use your information</strong></p>

                        <p>We use the information we collect in various ways, including to:</p>

                        <ul>
                        <li>Provide, operate, and maintain our webste</li>
                        <li>Improve, personalize, and expand our webste</li>
                        <li>Understand and analyze how you use our webste</li>
                        <li>Develop new products, services, features, and functionality</li>
                        <li>Communicate with you, either directly or through one of our partners, including for customer service, to provide you with updates and other information relating to the webste, and for marketing and promotional purposes</li>
                        <li>Send you emails</li>
                        <li>Find and prevent fraud</li>
                        </ul>

                        <p><strong>Log Files</strong></p>

                        <p>MR BEYAZ BOUTIQUE HOTEL follows a standard procedure of using log files. These files log visitors when they visit websites. All hosting companies do this and a part of hosting services' analytics. The information collected by log files include internet protocol (IP) addresses, browser type, Internet Service Provider (ISP), date and time stamp, referring/exit pages, and possibly the number of clicks. These are not linked to any information that is personally identifiable. The purpose of the information is for analyzing trends, administering the site, tracking users' movement on the website, and gathering demographic information.</p>

                        <p><strong>Cookies and Web Beacons</strong></p>

                        <p>Like any other website, MR BEYAZ BOUTIQUE HOTEL uses 'cookies'. These cookies are used to store information including visitors' preferences, and the pages on the website that the visitor accessed or visited. The information is used to optimize the users' experience by customizing our web page content based on visitors' browser type and/or other information.</p>

                        <p><strong>Advertising Partners Privacy Policies</strong></p>

                        <p>You may consult this list to find the Privacy Policy for each of the advertising partners of MR BEYAZ BOUTIQUE HOTEL.</p>

                        <p>Third-party ad servers or ad networks uses technologies like cookies, JavaScript, or Web Beacons that are used in their respective advertisements and links that appear on MR BEYAZ BOUTIQUE HOTEL, which are sent directly to users' browser. They automatically receive your IP address when this occurs. These technologies are used to measure the effectiveness of their advertising campaigns and/or to personalize the advertising content that you see on websites that you visit.</p>

                        <p>Note that MR BEYAZ BOUTIQUE HOTEL has no access to or control over these cookies that are used by third-party advertisers.</p>

                        <p><strong>Third Party Privacy Policies</strong></p>

                        <p>MR BEYAZ BOUTIQUE HOTEL's Privacy Policy does not apply to other advertisers or websites. Thus, we are advising you to consult the respective Privacy Policies of these third-party ad servers for more detailed information. It may include their practices and instructions about how to opt-out of certain options. </p>

                        <p>You can choose to disable cookies through your individual browser options. To know more detailed information about cookie management with specific web browsers, it can be found at the browsers' respective websites.</p>

                        <p><strong>CCPA Privacy Rights (Do Not Sell My Personal Information)</strong></p>

                        <p>Under the CCPA, among other rights, California consumers have the right to:</p>
                        <p>Request that a business that collects a consumer's personal data disclose the categories and specific pieces of personal data that a business has collected about consumers.</p>
                        <p>Request that a business delete any personal data about the consumer that a business has collected.</p>
                        <p>Request that a business that sells a consumer's personal data, not sell the consumer's personal data.</p>
                        <p>If you make a request, we have one month to respond to you. If you would like to exercise any of these rights, please contact us.</p>

                        <p><strong>GDPR Data Protection Rights</strong></p>

                        <p>We would like to make sure you are fully aware of all of your data protection rights. Every user is entitled to the following:</p>
                        <p>The right to access – You have the right to request copies of your personal data. We may charge you a small fee for this service.</p>
                        <p>The right to rectification – You have the right to request that we correct any information you believe is inaccurate. You also have the right to request that we complete the information you believe is incomplete.</p>
                        <p>The right to erasure – You have the right to request that we erase your personal data, under certain conditions.</p>
                        <p>The right to restrict processing – You have the right to request that we restrict the processing of your personal data, under certain conditions.</p>
                        <p>The right to object to processing – You have the right to object to our processing of your personal data, under certain conditions.</p>
                        <p>The right to data portability – You have the right to request that we transfer the data that we have collected to another organization, or directly to you, under certain conditions.</p>
                        <p>If you make a request, we have one month to respond to you. If you would like to exercise any of these rights, please contact us.</p>

                        <p><strong>Children's Information</strong></p>

                        <p>Another part of our priority is adding protection for children while using the internet. We encourage parents and guardians to observe, participate in, and/or monitor and guide their online activity.</p>

                        <p>MR BEYAZ BOUTIQUE HOTEL does not knowingly collect any Personal Identifiable Information from children under the age of 13. If you think that your child provided this kind of information on our website, we strongly encourage you to contact us immediately and we will do our best efforts to promptly remove such information from our records.</p>
                    </div>
                )       
            case 'fr':
                return(
                    <div className = "text-justify">
                        <p> <strong> Politique de confidentialité </strong> </p>

                        <p> Au MR BEYAZ BOUTIQUE HOTEL, accessible depuis https://mrbeyazbutikhotel.com/, l'une de nos principales priorités est la confidentialité de nos visiteurs. Ce document de politique de confidentialité contient des types d'informations collectées et enregistrées par MR BEYAZ BOUTIQUE HOTEL et comment nous les utilisons. </p>

                        <p> Si vous avez des questions supplémentaires ou avez besoin de plus d'informations sur notre politique de confidentialité, n'hésitez pas à nous contacter. </p>

                        <p> Cette politique de confidentialité s'applique uniquement à nos activités en ligne et est valable pour les visiteurs de notre site Web en ce qui concerne les informations qu'ils ont partagées et / ou collectées dans MR BEYAZ BOUTIQUE HOTEL. </p>

                        <p><strong>Consentement</strong></p>

                        <p> En utilisant notre site Web, vous acceptez par les présentes notre politique de confidentialité et acceptez ses conditions. </p>

                        <p> <strong> Informations que nous collectons </strong> </p>

                        <p> Les informations personnelles que l'on vous demande de fournir, et les raisons pour lesquelles vous êtes invité à les fournir, vous seront expliquées clairement au moment où nous vous demandons de fournir vos informations personnelles. </p>
                        <p> Si vous nous contactez directement, nous pouvons recevoir des informations supplémentaires à votre sujet telles que votre nom, votre adresse e-mail, votre numéro de téléphone, le contenu du message et / ou des pièces jointes que vous pouvez nous envoyer, et toute autre information que vous pouvez choisir de fournir . </p>
                        <p> Lorsque vous vous inscrivez pour un compte, nous pouvons vous demander vos coordonnées, y compris des éléments tels que le nom, le nom de l'entreprise, l'adresse, l'adresse e-mail et le numéro de téléphone. </p>

                        <p> <strong> Comment nous utilisons vos informations </strong> </p>

                        <p> Nous utilisons les informations que nous collectons de différentes manières, notamment pour: </p>

                        <ul>
                        <li> Fournir, exploiter et maintenir notre site Web </li>
                        <li> Améliorez, personnalisez et développez notre site Web </li>
                        <li> Comprendre et analyser la manière dont vous utilisez notre webste </li>
                        <li> Développer de nouveaux produits, services, fonctionnalités et fonctionnalités </li>
                        <li> Communiquez avec vous, directement ou par l’intermédiaire de l’un de nos partenaires, y compris pour le service client, pour vous fournir des mises à jour et d’autres informations relatives au Webste, et à des fins de marketing et de promotion </li>
                        <li> Vous envoyer des e-mails </li>
                        <li> Identifiez et prévenez la fraude </li>
                        </ul>

                        <p> <strong> Fichiers journaux </strong> </p>

                        <p> MR BEYAZ BOUTIQUE HOTEL suit une procédure standard d'utilisation des fichiers journaux. Ces fichiers enregistrent les visiteurs lorsqu'ils visitent des sites Web. Toutes les sociétés d'hébergement font cela et font partie de l'analyse des services d'hébergement. Les informations collectées par les fichiers journaux comprennent les adresses de protocole Internet (IP), le type de navigateur, le fournisseur d'accès Internet (FAI), la date et l'heure, les pages de renvoi / sortie et éventuellement le nombre de clics. Ceux-ci ne sont liés à aucune information personnellement identifiable. Le but de ces informations est d'analyser les tendances, d'administrer le site, de suivre les mouvements des utilisateurs sur le site et de collecter des informations démographiques. </p>

                        <p> <strong> Cookies et balises Web </strong> </p>

                        <p> Comme tout autre site Web, MR BEYAZ BOUTIQUE HOTEL utilise des «cookies». Ces cookies sont utilisés pour stocker des informations, y compris les préférences des visiteurs et les pages du site Web auxquelles le visiteur a accédé ou visité. Les informations sont utilisées pour optimiser l'expérience des utilisateurs en personnalisant le contenu de notre page Web en fonction du type de navigateur des visiteurs et / ou d'autres informations. </p>

                        <p> <strong> Politiques de confidentialité des partenaires publicitaires </strong> </p>

                        <p> Vous pouvez consulter cette liste pour trouver la politique de confidentialité de chacun des partenaires publicitaires de MR BEYAZ BOUTIQUE HOTEL. </p>

                        <p> Les serveurs publicitaires ou réseaux publicitaires tiers utilisent des technologies telles que les cookies, JavaScript ou les balises Web qui sont utilisées dans leurs publicités respectives et les liens qui apparaissent sur MR BEYAZ BOUTIQUE HOTEL, qui sont envoyés directement au navigateur des utilisateurs. Ils reçoivent automatiquement votre adresse IP lorsque cela se produit. Ces technologies sont utilisées pour mesurer l'efficacité de leurs campagnes publicitaires et / ou pour personnaliser le contenu publicitaire que vous voyez sur les sites Web que vous visitez. </p>

                        <p> Notez que MR BEYAZ BOUTIQUE HOTEL n'a aucun accès ni aucun contrôle sur ces cookies qui sont utilisés par des annonceurs tiers. </p>

                        <p> <strong> Politiques de confidentialité des tiers </strong> </p>

                        <p> La politique de confidentialité de MR BEYAZ BOUTIQUE HOTEL ne s'applique pas aux autres annonceurs ou sites Web. Ainsi, nous vous conseillons de consulter les politiques de confidentialité respectives de ces tiers-party ad servers pour des informations plus détaillées. Il peut inclure leurs pratiques et des instructions sur la façon de refuser certaines options. </p>

                    <p> Vous pouvez choisir de désactiver les cookies via les options de votre navigateur individuel. Pour obtenir des informations plus détaillées sur la gestion des cookies avec des navigateurs Web spécifiques, vous pouvez les trouver sur les sites Web respectifs des navigateurs. </p>

                    <p> <strong> Droits de confidentialité de l'ACCP (ne pas vendre mes informations personnelles) </strong> </p>

                    <p> En vertu de la CCPA, entre autres droits, les consommateurs californiens ont le droit de: </p>
                    <p> Demander à une entreprise qui collecte les données personnelles d'un consommateur de divulguer les catégories et les données personnelles spécifiques qu'une entreprise a collectées sur les consommateurs. </p>
                    <p> Demander à une entreprise de supprimer toutes les données personnelles sur le consommateur qu'elle a collectées. </p>
                    <p> Demander qu'une entreprise qui vend les données personnelles d'un consommateur ne vende pas les données personnelles du consommateur. </p>
                    <p> Si vous faites une demande, nous avons un mois pour vous répondre. Si vous souhaitez exercer l'un de ces droits, veuillez nous contacter. </p>

                    <p> <strong> Droits de protection des données GDPR </strong> </p>

                    <p> Nous souhaitons nous assurer que vous connaissez parfaitement tous vos droits en matière de protection des données. Chaque utilisateur a droit à ce qui suit: </p>
                    <p> Le droit d'accès - Vous avez le droit de demander des copies de vos données personnelles. Nous pouvons vous facturer des frais minimes pour ce service. </p>
                    <p> Le droit de rectification - Vous avez le droit de demander que nous corrigions toute information que vous estimez inexacte. Vous avez également le droit de nous demander de compléter les informations que vous estimez incomplètes. </p>
                    <p> Le droit d'effacement - Vous avez le droit de demander que nous effacions vos données personnelles, sous certaines conditions. </p>
                    <p> Le droit de restreindre le traitement - Vous avez le droit de demander que nous restreignions le traitement de vos données personnelles, sous certaines conditions. </p>
                    <p> Le droit de vous opposer au traitement - Vous avez le droit de vous opposer à notre traitement de vos données personnelles, sous certaines conditions. </p>
                    <p> Le droit à la portabilité des données - Vous avez le droit de demander que nous transférions les données que nous avons collectées à une autre organisation, ou directement à vous, sous certaines conditions. </p>
                    <p> Si vous faites une demande, nous avons un mois pour vous répondre. Si vous souhaitez exercer l'un de ces droits, veuillez nous contacter. </p>

                    <p> <strong> Informations sur les enfants </strong> </p>

                    <p> Une autre partie de notre priorité consiste à protéger les enfants lorsqu'ils utilisent Internet. Nous encourageons les parents et tuteurs à observer, participer et / ou surveiller et guider leur activité en ligne. </p>

                    <p> MR BEYAZ BOUTIQUE HOTEL ne collecte sciemment aucune information personnelle identifiable d'enfants de moins de 13 ans. Si vous pensez que votre enfant a fourni ce type d'informations sur notre site Web, nous vous encourageons vivement à nous contacter immédiatement et nous le ferons nos meilleurs efforts pour supprimer rapidement ces informations de nos archives. </p>
                    </div>
                ) 
            case 'ru':
                return (
                    <div className = "text-justify">
                        <p> <strong> Политика конфиденциальности </strong> </p>

                        <p> В MR BEYAZ BOUTIQUE HOTEL, доступном по адресу https://mrbeyazbutikhotel.com/, одним из наших основных приоритетов является конфиденциальность наших посетителей. Этот документ Политики конфиденциальности содержит типы информации, которая собирается и записывается MR BEYAZ BOUTIQUE HOTEL, и способы ее использования. </p>

                        <p> Если у вас есть дополнительные вопросы или вам нужна дополнительная информация о нашей Политике конфиденциальности, не стесняйтесь обращаться к нам. </p>

                        <p> Настоящая Политика конфиденциальности применяется только к нашей онлайн-деятельности и действительна для посетителей нашего веб-сайта в отношении информации, которую они передали и / или собирали в MR BEYAZ BOUTIQUE HOTEL. </p>

                        <p><strong>Cогласие</strong></p>

                        <p> Используя наш веб-сайт, вы тем самым соглашаетесь с нашей Политикой конфиденциальности и соглашаетесь с ее условиями. </p>

                        <p> <strong> Информация, которую мы собираем </strong> </p>

                        <p> Персональная информация, которую вас просят предоставить, и причины, по которым вас просят предоставить ее, будут ясны вам в тот момент, когда мы просим вас предоставить вашу личную информацию. </p>
                        <p> Если вы обратитесь к нам напрямую, мы можем получить дополнительную информацию о вас, такую ​​как ваше имя, адрес электронной почты, номер телефона, содержание сообщения и / или вложения, которые вы можете нам отправить, а также любую другую информацию, которую вы можете предоставить. . </p>
                        <p> Когда вы регистрируете учетную запись, мы можем запросить вашу контактную информацию, включая такие элементы, как имя, название компании, адрес, адрес электронной почты и номер телефона. </p>

                        <p> <strong> Как мы используем вашу информацию </strong> </p>

                        <p> Мы используем информацию, которую собираем, различными способами, в том числе для: </p>

                        <ul>
                        <li> Предоставлять, эксплуатировать и поддерживать наш веб-сайт. </li>
                        <li> Улучшать, персонализировать и расширять наш веб-сайт. </li>
                        <li> Поймите и проанализируйте, как вы используете наш веб-сайт. </li>
                        <li> Разрабатывать новые продукты, услуги, функции и возможности. </li>
                        <li> Общаться с вами напрямую или через одного из наших партнеров, в том числе для обслуживания клиентов, чтобы предоставлять вам обновления и другую информацию, относящуюся к веб-сайту, а также в маркетинговых и рекламных целях. </li>
                        <li> Отправлять вам электронные письма </li>
                        <li> Найдите и предотвратите мошенничество. </li>
                        </ul>

                        <p> <strong> Файлы журнала </strong> </p>

                        <p> MR BEYAZ BOUTIQUE HOTEL следует стандартной процедуре использования файлов журналов. Эти файлы регистрируют посетителей, когда они посещают веб-сайты. Все хостинговые компании делают это и часть аналитики хостинговых услуг. Информация, собираемая файлами журнала, включает адреса интернет-протокола (IP), тип браузера, поставщика услуг Интернета (ISP), отметку даты и времени, страницы перехода / выхода и, возможно, количество кликов. Они не связаны с какой-либо информацией, позволяющей установить личность. Цель информации - анализ тенденций, администрирование сайта, отслеживание движения пользователей по сайту и сбор демографической информации. </p>

                        <p> <strong> Файлы cookie и веб-маяки </strong> </p>

                        <p> Как и любой другой веб-сайт, MR BEYAZ BOUTIQUE HOTEL использует файлы cookie. Эти файлы cookie используются для хранения информации, включая предпочтения посетителей и страницы веб-сайта, которые посетитель посещал или посещал. Информация используется для оптимизации работы пользователей путем настройки содержимого нашей веб-страницы на основе типа браузера посетителей и / или другой информации. </p>

                        <p> <strong> Политика конфиденциальности рекламных партнеров </strong> </p>

                        <p> Вы можете ознакомиться с этим списком, чтобы найти Политику конфиденциальности для каждого из рекламных партнеров MR BEYAZ BOUTIQUE HOTEL. </p>

                        <p> Сторонние рекламные серверы или рекламные сети используют такие технологии, как файлы cookie, JavaScript или веб-маяки, которые используются в их соответствующих рекламных объявлениях и ссылках, которые появляются в MR BEYAZ BOUTIQUE HOTEL и отправляются непосредственно в браузер пользователей. Когда это происходит, они автоматически получают ваш IP-адрес. Эти технологии используются для измерения эффективности их рекламных кампаний и / или для персонализации рекламного контента, который вы видите на посещаемых вами веб-сайтах. </p>

                        <p> Обратите внимание, что MR BEYAZ BOUTIQUE HOTEL не имеет доступа или контроля над этими файлами cookie, которые используются сторонними рекламодателями. </p>

                        <p> <strong> Политика конфиденциальности третьих лиц </strong> </p>

                        <p> Политика конфиденциальности MR BEYAZ BOUTIQUE HOTEL не распространяется на других рекламодателей или веб-сайты. Таким образом, мы рекомендуем вам ознакомиться с соответствующей Политикой конфиденциальности этих третьих лиц.rty рекламных серверов для получения более подробной информации. Он может включать их методы и инструкции о том, как отказаться от определенных вариантов. </p>

                        <p> Вы можете отключить файлы cookie в настройках вашего браузера. Чтобы узнать более подробную информацию об управлении файлами cookie в определенных веб-браузерах, ее можно найти на соответствующих веб-сайтах браузеров. </p>

                        <p> <strong> Права на конфиденциальность CCPA (не продавать мою личную информацию) </strong> </p>

                        <p> В соответствии с CCPA, среди прочих прав, потребители Калифорнии имеют право: </p>
                        <p> Требовать от компании, собирающей персональные данные потребителей, раскрытия категорий и конкретных частей персональных данных, которые компания собрала о потребителях. </p>
                        <p> Запросить у компании удаление всех собранных ею личных данных о потребителе. </p>
                        <p> Требовать, чтобы компания, которая продает персональные данные потребителя, не продавала персональные данные потребителя. </p>
                        <p> Если вы сделаете запрос, у нас будет один месяц, чтобы ответить вам. Если вы хотите воспользоваться любым из этих прав, свяжитесь с нами. </p>

                        <p> <strong> Права GDPR на защиту данных </strong> </p>

                        <p> Мы хотели бы убедиться, что вы полностью осведомлены обо всех своих правах на защиту данных. Каждый пользователь имеет право на следующее: </p>
                        <p> Право на доступ - вы имеете право запрашивать копии ваших личных данных. Мы можем взимать небольшую плату за эту услугу. </p>
                        <p> Право на исправление. Вы имеете право потребовать, чтобы мы исправили любую информацию, которую вы считаете неточной. Вы также имеете право потребовать, чтобы мы завершили информацию, которую вы считаете неполной. </p>
                        <p> Право на удаление - вы имеете право потребовать, чтобы мы удалили ваши личные данные при определенных условиях. </p>
                        <p> Право на ограничение обработки - вы имеете право потребовать, чтобы мы ограничили обработку ваших персональных данных при определенных условиях. </p>
                        <p> Право возражать против обработки - вы имеете право возражать против обработки нами ваших личных данных при определенных условиях. </p>
                        <p> Право на переносимость данных. Вы имеете право потребовать, чтобы мы передали собранные нами данные другой организации или непосредственно вам при определенных условиях. </p>
                        <p> Если вы сделаете запрос, у нас будет один месяц, чтобы ответить вам. Если вы хотите воспользоваться любым из этих прав, свяжитесь с нами. </p>

                        <p> <strong> Информация о детях </strong> </p>

                        <p> Еще одна наша приоритетная задача - обеспечить защиту детей при использовании Интернета. Мы призываем родителей и опекунов наблюдать, участвовать и / или отслеживать и направлять их действия в Интернете. </p>

                        <p> MR BEYAZ BOUTIQUE HOTEL сознательно не собирает личную информацию, позволяющую установить личность детей в возрасте до 13 лет. Если вы считаете, что ваш ребенок предоставил такую ​​информацию на нашем веб-сайте, мы настоятельно рекомендуем вам немедленно связаться с нами, и мы сделаем это. мы делаем все возможное, чтобы как можно скорее удалить такую ​​информацию из наших записей. </p>
                        </div>
                )
            default:
                return (
                    <div className="text-justify">
                        <p> <strong> Gizlilik Politikası </strong> </p>

                        <p> https://mrbeyazbutikhotel.com/ adresinden erişilebilen MR BEYAZ BOUTIQUE HOTEL'de ana önceliklerimizden biri ziyaretçilerimizin mahremiyetidir. Bu Gizlilik Politikası belgesi, MR BEYAZ BOUTIQUE HOTEL tarafından toplanan ve kaydedilen bilgi türlerini ve bunları nasıl kullandığımızı içerir. </p>

                        <p> Başka sorularınız varsa veya Gizlilik Politikamız hakkında daha fazla bilgiye ihtiyaç duyuyorsanız, bizimle iletişime geçmekten çekinmeyin. </p>

                        <p> Bu Gizlilik Politikası yalnızca çevrimiçi etkinliklerimiz için geçerlidir ve web sitemizi ziyaret edenler için MR BEYAZ BOUTIQUE HOTEL'de paylaştıkları ve / veya topladıkları bilgilerle ilgili olarak geçerlidir. </p>

                        <p><strong>Konsensüs</strong> </p>

                        <p> Web sitemizi kullanarak, Gizlilik Politikamıza izin veriyor ve şartlarını kabul ediyorsunuz. </p>

                        <p> <strong> Topladığımız bilgiler </strong> </p>

                        <p> Vermeniz istenen kişisel bilgiler ve bu bilgileri vermenizin istenmesinin nedenleri, sizden kişisel bilgilerinizi vermenizi istediğimiz noktada size açıklanacaktır. </p>
                        <p> Bizimle doğrudan iletişime geçerseniz, adınız, e-posta adresiniz, telefon numaranız, bize gönderebileceğiniz mesaj ve / veya ekler gibi sizinle ilgili ek bilgiler ve sağlamayı seçebileceğiniz diğer bilgiler alabiliriz. . </p>
                        <p> Bir Hesap için kaydolduğunuzda, ad, şirket adı, adres, e-posta adresi ve telefon numarası gibi öğeler dahil olmak üzere iletişim bilgilerinizi isteyebiliriz. </p>

                        <p> <strong> Bilgilerinizi nasıl kullanıyoruz </strong> </p>

                        <p> Topladığımız bilgileri aşağıdakiler dahil çeşitli şekillerde kullanırız: </p>

                        <ul>
                        <li> Web sitemizi sağlayın, çalıştırın ve bakımını yapın </li>
                        <li> Web sitemizi iyileştirin, kişiselleştirin ve genişletin </li>
                        <li> Web sitemizi nasıl kullandığınızı anlayın ve analiz edin </li>
                        <li> Yeni ürünler, hizmetler, özellikler ve işlevler geliştirin </li>
                        <li> Size web sitesine ilişkin güncellemeleri ve diğer bilgileri sağlamak ve pazarlama ve promosyon amaçları için müşteri hizmetleri dahil olmak üzere doğrudan veya ortaklarımızdan biri aracılığıyla sizinle iletişim kurmak </li>
                        <li> Size e-posta gönderin </li>
                        <li> Dolandırıcılığı bulun ve önleyin </li>
                        </ul>

                        <p> <strong> Günlük Dosyaları </strong> </p>

                        <p> MR BEYAZ BOUTIQUE HOTEL, günlük dosyalarını kullanma konusunda standart bir prosedür izler. Bu dosyalar, ziyaretçileri web sitelerini ziyaret ettiklerinde kaydeder. Tüm barındırma şirketleri bunu yapar ve barındırma hizmetlerinin analizinin bir parçasıdır. Günlük dosyaları tarafından toplanan bilgiler arasında internet protokolü (IP) adresleri, tarayıcı türü, İnternet Servis Sağlayıcısı (ISS), tarih ve saat damgası, yönlendirme / çıkış sayfaları ve muhtemelen tıklama sayısı bulunur. Bunlar kişisel olarak tanımlanabilir herhangi bir bilgi ile bağlantılı değildir. Bilgilerin amacı eğilimleri analiz etmek, siteyi yönetmek, kullanıcıların web sitesindeki hareketlerini izlemek ve demografik bilgileri toplamaktır. </p>

                        <p> <strong> Çerezler ve Web İşaretçileri </strong> </p>

                        <p> Diğer tüm web siteleri gibi, MR BEYAZ BOUTIQUE HOTEL de 'çerezler' kullanır. Bu çerezler, ziyaretçilerin tercihleri ​​ve ziyaretçinin eriştiği veya ziyaret ettiği web sitesindeki sayfalar dahil olmak üzere bilgileri depolamak için kullanılır. Bilgiler, web sayfası içeriğimizi ziyaretçilerin tarayıcı türüne ve / veya diğer bilgilere göre özelleştirerek kullanıcıların deneyimini optimize etmek için kullanılır. </p>

                        <p> <strong> Reklam Ortakları Gizlilik Politikaları </strong> </p>

                        <p> MR BEYAZ BOUTIQUE HOTEL'in her bir reklam ortağı için Gizlilik Politikasını bulmak için bu listeye başvurabilirsiniz. </p>

                        <p> Üçüncü taraf reklam sunucuları veya reklam ağları, doğrudan kullanıcıların tarayıcısına gönderilen MR BEYAZ BOUTIQUE HOTEL'de görünen ilgili reklamlarında ve bağlantılarında kullanılan çerezler, JavaScript veya Web İşaretçileri gibi teknolojileri kullanır. Bu gerçekleştiğinde IP adresinizi otomatik olarak alırlar. Bu teknolojiler, reklam kampanyalarının etkinliğini ölçmek ve / veya ziyaret ettiğiniz web sitelerinde gördüğünüz reklam içeriğini kişiselleştirmek için kullanılır. </p>

                        <p> MR BEYAZ BOUTIQUE HOTEL'in üçüncü taraf reklamverenler tarafından kullanılan bu tanımlama bilgilerine erişimi veya kontrolü olmadığını unutmayın. </p>

                        <p> <strong> Üçüncü Taraf Gizlilik Politikaları </strong> </p>

                        <p> MR BEYAZ BOUTIQUE HOTEL'in Gizlilik Politikası diğer reklamcılar veya web siteleri için geçerli değildir. Bu nedenle, bu üçüncü sayfanın ilgili Gizlilik Politikalarına başvurmanızı tavsiye ediyoruz.Daha ayrıntılı bilgi için rty reklam sunucuları. Onların uygulamalarını ve belirli seçeneklerden nasıl çıkılacağına ilişkin talimatları içerebilir. </p>

                        <p> Kişisel tarayıcı seçenekleriniz aracılığıyla çerezleri devre dışı bırakmayı seçebilirsiniz. Belirli web tarayıcılarında çerez yönetimi hakkında daha ayrıntılı bilgi edinmek için, tarayıcıların ilgili web sitelerinde bulunabilir. </p>

                        <p> <strong> CCPA Gizlilik Hakları (Kişisel Bilgilerimi Satmayın) </strong> </p>

                        <p> CCPA kapsamında, diğer hakların yanı sıra, Kaliforniyalı tüketiciler şu haklara sahiptir: </p>
                        <p> Tüketicinin kişisel verilerini toplayan bir işletmenin, bir işletmenin tüketiciler hakkında topladığı kategorileri ve belirli kişisel veri parçalarını ifşa etmesini isteyin. </p>
                        <p> Bir işletmenin, bir işletmenin topladığı tüketiciyle ilgili tüm kişisel verileri silmesini isteyin. </p>
                        <p> Tüketicinin kişisel verilerini satmayan, tüketicinin kişisel verilerini satan bir işletmeden isteyin. </p>
                        <p> Bir istekte bulunursanız, size yanıt vermek için bir ayımız var. Bu haklardan herhangi birini kullanmak isterseniz, lütfen bizimle iletişime geçin. </p>

                        <p> <strong> GDPR Veri Koruma Hakları </strong> </p>

                        <p> Tüm veri koruma haklarınızdan tam olarak haberdar olduğunuzdan emin olmak istiyoruz. Her kullanıcı şunlara hak kazanır: </p>
                        <p> Erişim hakkı - Kişisel verilerinizin kopyalarını talep etme hakkına sahipsiniz. Bu hizmet için sizden küçük bir ücret alabiliriz. </p>
                        <p> Düzeltme hakkı - Yanlış olduğuna inandığınız herhangi bir bilgiyi düzeltmemizi talep etme hakkına sahipsiniz. Ayrıca, eksik olduğuna inandığınız bilgileri tamamlamamızı talep etme hakkına da sahipsiniz. </p>
                        <p> Silme hakkı - Belirli koşullar altında kişisel verilerinizi silmemizi talep etme hakkına sahipsiniz. </p>
                        <p> İşlemeyi kısıtlama hakkı - Belirli koşullar altında kişisel verilerinizin işlenmesini kısıtlamamızı talep etme hakkına sahipsiniz. </p>
                        <p> İşlemeye itiraz etme hakkı - Belirli koşullar altında kişisel verilerinizi işlememize itiraz etme hakkına sahipsiniz. </p>
                        <p> Veri taşınabilirliği hakkı - Topladığımız verileri belirli koşullar altında başka bir kuruluşa veya doğrudan size aktarmamızı talep etme hakkına sahipsiniz. </p>
                        <p> Bir istekte bulunursanız, size yanıt vermek için bir ayımız var. Bu haklardan herhangi birini kullanmak isterseniz, lütfen bizimle iletişime geçin. </p>

                        <p> <strong> Çocuklara İlişkin Bilgiler </strong> </p>

                        <p> Önceliğimizin bir başka parçası da interneti kullanırken çocuklar için koruma sağlamaktır. Ebeveynleri ve velileri çevrimiçi etkinliklerini gözlemlemeye, katılmaya ve / veya izlemeye ve yönlendirmeye teşvik ediyoruz. </p>

                        <p> MR BEYAZ BOUTIQUE HOTEL, 13 yaşın altındaki çocuklardan bilerek Kişisel Tanımlanabilir Bilgi toplamaz Çocuğunuzun web sitemizde bu tür bilgileri verdiğini düşünüyorsanız, derhal bizimle iletişime geçmenizi şiddetle tavsiye ederiz ve yapacağız. bu tür bilgileri kayıtlarımızdan derhal kaldırmak için elimizden gelenin en iyisini yapıyoruz. </p>
                    </div>
                )
        }
    }
}

export default CookiePolicyEn;