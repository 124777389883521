import React, { Component } from 'react'
import Headerfour from '../layouts/Headerfour';
import Footer from '../layouts/Footer';
import { Link } from 'react-router-dom';
import {info} from '../constants'
import i18n from 'i18next'

class Contact extends Component {
  render() {
    const t = o => {
      return i18n.t(o);
    }


    const WebFormSubmit = (e) => {
      e.preventDefault();

      var form = document.querySelector("form");
      var request = new XMLHttpRequest();
  
      request.onreadystatechange = function() {
        if (this.readyState === 4 && this.status === 200) {
            var res = this.responseText;
            var status = res.split('.')[1];
            
            var alert = document.createElement('div');

            alert.setAttribute('class', 'alert alert-' + status);
            alert.innerHTML = '<strong>' + t(res) +  '</strong>';

            document.getElementById('AlertWrapper').appendChild(alert);

            setTimeout(() => {
              form.reset();
              document.getElementById('fieldset').removeAttribute('disabled');
              document.getElementById('spinner').style.display = 'none';
              alert.remove();
            }, 1024);
        
        }
      }
      request.open("POST", "/mail/");
      request.send(new FormData(form));
      document.getElementById('fieldset').setAttribute('disabled','disabled');
      document.getElementById('spinner').style.display = 'inline-block';
    }

    return (
      <div>
        <Headerfour />
        {/*====== BREADCRUMB PART START ======*/}
        <section className="breadcrumb-area" style={{backgroundImage: 'url(assets/img/bg/04.jpg)'}}>
          <div className="container">
            <div className="breadcrumb-text">
              <span>{t('room.subtitle')}</span>
              <h2 className="page-title">{t('contact.us')}</h2>
              <ul className="breadcrumb-nav">
                <li><Link to="/">{t('header.home')}</Link></li>
                <li className="active">{t('header.contact')}</li>
              </ul>
            </div>
          </div>
        </section>
        {/*====== BREADCRUMB PART END ======*/}
        {/*====== CONTACT PART START ======*/}
        <section className="contact-part pt-115 pb-115">
          <div className="container">
            {/* Contact Info */}
            <div className="contact-info">
              <div className="row justify-content-center">
                <div className="col-lg-4 col-sm-6 col-10">
                  <div className="info-box h-100">
                    <div className="icon">
                      <i className="flaticon-home" />
                    </div>
                    <div className="desc">
                      <h4>{t('info.address')}</h4>
                      <p>{info.address}</p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-sm-6 col-10">
                  <div className="info-box h-100">
                    <div className="icon">
                      <i className="flaticon-phone" />
                    </div>
                    <div className="desc">
                      <h4>{t('info.phone')}</h4>
                      <p>{info.phone}</p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-sm-6 col-10">
                  <div className="info-box h-100">
                    <div className="icon">
                      <i className="flaticon-message" />
                    </div>
                    <div className="desc">
                      <h4>{t('info.email')}</h4>
                      <p>{info.email}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Contact Mapts */}
            <div className="contact-maps mb-30 mt-30">
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d752.1418712788471!2d28.99095050349443!3d41.05658657803514!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14cab7059bf46ed3%3A0xe404b9df47561fd5!2zTWXFn3J1dGl5ZXQsIFN1bmEgS8SxcmHDpyBTay4gTm86NCwgMzQzNjMgxZ5pxZ9saS_EsHN0YW5idWwsIFTDvHJraXll!5e0!3m2!1str!2sao!4v1611582359049!5m2!1str!2sao" width="600" height="450" frameBorder="0" allowFullScreen="" aria-hidden="false" tabIndex="0" title="MR BEYAZ BUTIK HOTEL" className="border-0"></iframe>
            </div>
            <div className="mb-30 mt-30 text-center">
              <p className="px-3">
                <strong>İstanbul (IST)</strong><small> - 30 { t('min_car') }</small>, <strong>İstanbul (SAW-Sabiha Gökçen Uluslararası)</strong><small> - 45 { t('min_car') }</small>, <strong>Yenikapı Metro</strong><small> - 7 { t('min_car') }</small>, <strong>İstanbul Kocamustafapaşa Metro</strong><small> - 9 { t('min_car') }</small>, 
                <strong>Osmanbey Metro</strong><small> - 5 { t('min') }</small>, <strong>Şişli Metro</strong><small> - 12 { t('min') }</small>, <strong>{ t('contact.airport') }</strong>, <strong>{ t('contact.local') }</strong>
              </p> 
            </div>
            {/* Contact Form */}
            <div className="contact-form">
              <form action="#" onSubmit={(e) => WebFormSubmit(e)}>
                <fieldset id="fieldset" disabled="">
                  <div className="row">
                    <div className="col-12 mb-30">
                      <h3>{ t('contact.us') }</h3>
                    </div>
                    <div className="col-md-6">
                      <div className="input-group mb-30">
                        <span className="icon"><i className="far fa-user" /></span>
                        <input type="text" name="name" placeholder={t('contact.form.name')} required />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="input-group mb-30">
                        <span className="icon"><i className="far fa-envelope" /></span>
                        <input type="email" name="email" placeholder={t('contact.form.email')} required />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="input-group mb-30">
                        <span className="icon"><i className="far fa-phone" /></span>
                        <input type="text" name="phone" placeholder={t('contact.form.phone')} />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="input-group mb-30">
                        <span className="icon"><i className="far fa-book" /></span>
                        <input type="text" name="subject" placeholder={t('contact.form.subject')} required />
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="input-group textarea mb-30">
                        <span className="icon"><i className="far fa-pen" /></span>
                        <textarea type="text" name="message" placeholder={t('contact.form.message')} required defaultValue={""} />
                      </div>
                    </div>
                    <div className="col-12 text-center">
                      <button type="submit" className="main-btn btn-filled">
                        <span className="spinner-border spinner-border-sm mr-3" style={{display: 'none'}} role="status" aria-hidden="true" id="spinner"></span>
                        {t('contact.form.button')}
                      </button>
                    </div>
                    <div className="col-12 py-3" id="AlertWrapper"></div>
                  </div>
                </fieldset>
              </form>
            </div>
          </div>
        </section>
        {/*====== CONTACT PART END ======*/}
        <Footer />
      </div>

    );
  }
}

export default Contact;
