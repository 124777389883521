import React, { Component } from 'react'
import Headerfour from '../layouts/Headerfour';
import Footer from '../layouts/Footer';
import Breadcrumb from '../sections/offers/Breadcrumb';
import Offer from '../sections/offers/Offers';
import i18n from '../../i18n'

class Offers extends Component {
  render() {
    const t = o => {
      return i18n.t(o);
    }

    return (
      <div>
        <Headerfour />
        {/*====== BREADCRUMB PART START ======*/}
        <Breadcrumb/>
        {/*====== BREADCRUMB PART END ======*/}
        <Offer t={t} />
        {/*====== FEATURE ROOM START ======*/}
        <Footer />
      </div>

    );
  }
}

export default Offers;
