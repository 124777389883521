import React, { Component } from 'react'
import Headerfour from '../layouts/Headerfour';
import Footer from '../layouts/Footer';
import { Link } from 'react-router-dom';
import i18n from 'i18next'
import {rooms} from '../constants'

class Roomlist extends Component {
  render() {
    const t = obj => {
      return i18n.t(obj);
    }

    return (
      <div>
        <Headerfour />
        {/*====== BREADCRUMB PART START ======*/}
        <section className="breadcrumb-area" style={{backgroundImage: 'url(assets/img/bg/04.jpg)'}}>
          <div className="container">
            <div className="breadcrumb-text">
              <span>{t('room.subtitle')}</span>
              <h2 className="page-title">{t('room.plr')}</h2>
              <ul className="breadcrumb-nav">
                <li><Link to="/">{t('header.home')}</Link></li>
                <li className="active">{t('header.rooms')}</li>
              </ul>
            </div>
          </div>
        </section>
        {/*====== BREADCRUMB PART END ======*/}
        {/*====== ROOM-LIST START ======*/}
        <section className="room-section room-list-style pt-120 pb-120">
          <div className="container">
            <div className="room-list-loop">
            {rooms.map((item, i) => (
              <div key={i} className="room-box mb-30">
                <div className="row no-gutters justify-content-center">
                  <div className="col-lg-5 col-md-10">
                    <div className="room-img-wrap">
                      <div className="room-img" style={{backgroundImage: "url("+ item.photo + ")"}} />
                    </div>
                  </div>
                  <div className="col-lg-7 col-md-10">
                    <div className="room-desc h-100">
                      <div className="row align-items-center">
                        <div className="col-sm-8">
                          <ul className="icons">
                            <li><i className="fal fa-bed" /></li>
                            <li><i className="fal fa-wifi" /></li>
                            <li><i className="fal fa-car" /></li>
                            <li><i className="fal fa-coffee" /></li>
                            <li><i className="fal fa-concierge-bell" /></li>
                            <li><i className="fal fa-compress-arrows-alt" /></li>
                          </ul>
                          <h4 className="title"><Link to={item.url}>{item.title}</Link></h4>
                          <p>
                          {t(item.desc)}
                          </p>
                        </div>
                        <div className="col-sm-4">
                          <div className="text-sm-center">
                            <span className="price">{item.price}<span>/{t('booking.night')}</span> </span>
                            <Link to={item.url} className="book-btn">{t(item.book)}</Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}

            </div>
          </div>
        </section>
        {/*====== ROOM-LIST END ======*/}
        <Footer />
      </div>

    );
  }
}

export default Roomlist;
