import React, { Component } from 'react'
import Headerfour from '../layouts/Headerfour';
import Footer from '../layouts/Footer';
import { Link } from 'react-router-dom';
import i18n from 'i18next'
import CookiePolicyTranslated from '../sections/cookies/kvkk'


class CookiePolicy extends Component {
  render() {
    const t = o => {
      return i18n.t(o);
    }
    
    const lang = i18n.language;

    return (
      <div>
        <Headerfour />
        {
            <section className="breadcrumb-area" style={{backgroundImage: 'url(assets/img/bg/04.jpg)'}}>
                <div className="container">
                  <div className="breadcrumb-text">
                      <span>{t('room.subtitle')}</span>
                      <h2 className="page-title">{t('cookie.kvkk')}</h2>
                      <ul className="breadcrumb-nav">
                      <li><Link to="/">{t('header.home')}</Link></li>
                      <li className="active">{t('cookie.kvkk')}</li>
                      </ul>
                  </div>
                </div>
            </section>
        }
        {<section className="about-section pt-115 pb-115">
            <div className="container">
                <div className="cookie-policy">
                  <CookiePolicyTranslated lang={lang} />
                </div>
                

            </div>
        </section>}
        <Footer />
      </div>
    );
  }
}

export default CookiePolicy;