import React, { Component } from 'react';
import { Link } from 'react-router-dom'; 
import Slider from "react-slick"; 

function importAll(r) {
    return r.keys().map(r);
}

const gallery = importAll(require.context('../../../assets/img/room/', true, /\.(png|jpe?g|svg)$/));

class GallerySlider extends Component {
  render() {
    const settings = {
        slidesToShow: 6,
        slidesToScroll: 1,
        fade: false,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 4048,
        arrows: false,
        dots: false,
        lazyLoad: true,
        responsive: [
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 4,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 3,
                },
            },
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 2,
                },
            },
        ],
    };
    return (
        <section className="gallery-feed-section">
            <div className="container-fluid p-0">
            <Slider className="gallery-slider" {...settings}>
                {gallery.map((item, i) => (
                    <div key={i} className="image">
                        <Link to="/gallery" className="gallery-popup">
                            <img src={item} alt={item} />
                        </Link>
                    </div>
                ))}
            </Slider>
            </div>
        </section>
    );
  }
}

export default GallerySlider;