import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class Breadcrumb extends Component {
  render() {
    const t = this.props.t;

    return (
      <section className="breadcrumb-area" style={{backgroundImage: 'url(assets/img/bg/04.jpg)'}}>
          <div className="container">
            <div className="breadcrumb-text">
              <span>{t('room.subtitle')}</span>
              <h2 className="page-title">{t('about.us')}</h2>
              <ul className="breadcrumb-nav">
                <li><Link to="/">{t('header.home')}</Link></li>
                <li className="active">{t('header.about')}</li>
              </ul>
            </div>
          </div>
        </section>
    );
  }
}

export default Breadcrumb;
