import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import i18n from 'i18next'

class Breadcrumb extends Component {
  render() {
    const t = o => {
      return i18n.t(o);
    }

    return (
      <section className="breadcrumb-area" style={{backgroundImage: 'url(assets/img/bg/04.jpg)'}}>
        <div className="container">
          <div className="breadcrumb-text">
            <span>{t('room.subtitle')}</span>
            <h2 className="page-title">{t('restaurant.name')}</h2>
            <ul className="breadcrumb-nav">
              <li><Link to="/">{t('header.home')}</Link></li>
              <li className="active">{t('restaurant.name')}</li>
            </ul>
          </div>
        </div>
      </section>
    );
  }
}

export default Breadcrumb;
