import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import {info} from '../constants';
import AGEWBTECHS from './agewebtechs';

class Footertwo extends Component {
  constructor(props) {
    super(props);
    this.state = {
       redText: false
    };
  }
  componentDidMount() {
    window.addEventListener('scroll', () => {
      this.setState({
          isTop: window.scrollY > 300
      });
  }, false);
  }
  scrollToTop() {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  }
  render() {
    const className = this.state.isTop ? 'active' : '';
    const t = this.props.t;

    const services = [];

    for (let i = 0; i < 9; i++) {
      services.push(
        <li key={i}><Link to="#">{t('services.s' + i)}</Link></li>
      )      
    }

    return (
      <div>
        {/*====== Back to Top ======*/}
        <Link to="#" className={`back-to-top ${className}`} id="backToTop" onClick={() => this.scrollToTop()}>
          <i className="fal fa-angle-double-up" />
        </Link>
        {/*====== FOOTER START ======*/}
        <footer className="footer-two">
          <div className="footer-widget-area pt-100 pb-50" style={{backgroundImage: 'url(assets/img/icon/pattern-01.png)'}}>
            <div className="container">
              <div className="row">
                <div className="col-lg-3 col-sm-6 order-1">
                  {/* Site Info Widget */}
                  <div className="widget site-info-widget mb-50">
                    <div className="footer-logo mb-50 text-center">
                      <img src="assets/img/logo-white.png" alt="" />
                    </div>
                    <p>
                      {t('footer.description')}
                    </p>
                  </div>
                </div>
                <div className="col-lg-6 order-3 order-lg-2">
                  {/* Nav Widget */}
                  <div className="widget nav-widget mb-50">
                    <div>
                      <h4 className="widget-title mb-0">{t('services.title')}</h4>
                      <p className="mb-20 hotel-text-primary">{t('services.subtitle')}</p>
                      <ul className="text-uppercase">
                        {services}
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-sm-6 order-2 order-lg-3">
                  {/* Contact Widget */}
                  <div className="widget contact-widget mb-50">
                    <h4 className="widget-title">{t('contact.us')}</h4>
                    <div className="contact-lists">
                      <div className="contact-box">
                        <div className="icon">
                          <i className="flaticon-call" />
                        </div>
                        <div className="desc">
                          <h6 className="title">{t('info.phone')}</h6>
                          <Link to={`tel:${info.phone}`}>
                            {info.phone}
                          </Link>
                        </div>
                      </div>
                      <div className="contact-box">
                        <div className="icon">
                          <i className="flaticon-message" />
                        </div>
                        <div className="desc">
                          <h6 className="title">Email</h6>
                          <Link to={`mailto:${info.email}`}>
                            {info.email}
                          </Link>
                        </div>
                      </div>
                      <div className="contact-box">
                        <div className="icon">
                          <i className="flaticon-location-pin" />
                        </div>
                        <div className="desc">
                          <h6 className="title">{t('info.address')}</h6>
                          {info.address}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="copyright-area pt-30 pb-30">
            <div className="container">
              <div className="row align-items-center">
                <div className="col-lg-6 col-md-5 order-2 order-md-1">
                  <AGEWBTECHS />
                </div>
                <div className="col-lg-6 col-md-7 order-1 order-md-2">
                  <div className="footer-menu text-center text-md-right">
                    <ul>
                      <li><Link to="/cookie-policy">{t('cookie.policy')}</Link></li>
                      <li><Link to="/privacy-policy">{t('cookie.kvkk')}</Link></li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </footer>
        {/*====== FOOTER END ======*/}
      </div>
    );
  }
}

export default Footertwo;
