import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import ReactWOW from 'react-wow'
import Slider from "react-slick";

class Textblock extends Component {
    render() {
        const settings = {
            infinite: true,
            autoplay: true,
            autoplaySpeed: 5000,
            dots: false,
            fade: true,
            arrows: false,
            pauseOnHover: false
        };

        const t = this.props.t;

    return (
        <section className="text-block pt-115 pb-115">
            <div className="container">
                <ReactWOW animation="fadeInLeft" data-wow-delay=".3s">
                    <div className="row align-items-center justify-content-center">
                        <div className="col-lg-7">
                            <div className="text-img text-center text-lg-left mb-small">
                                <Slider {...settings}>
                                    <img src="assets/img/text-block/01.jpg" alt="" />
                                    <img src="assets/img/text-block/02.jpg" alt="" />
                                </Slider>
                            </div>
                        </div>
                        <ReactWOW animation="fadeInRight" data-wow-delay=".5s">
                            <div className="col-lg-5 col-md-8 col-sm-10">
                                <div className="block-text">
                                    <div className="section-title mb-20 pl-0">

                                        <h2>{t('textblock.title')}</h2>
                                        </div>
                                        <p>
                                            {t('textblock.p')}
                                        </p>
                                        <Link to="/gallery" className="main-btn btn-filled mt-40">{t('take.a.tour')}</Link>
                                    </div>
                                </div>
                        </ReactWOW>
                    </div>
                </ReactWOW>
            </div>
        </section>
        
    );
  }
}

export default Textblock;
