import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import ReactWOW from 'react-wow'
import {core_flaticons} from '../../constants'

class Corefeature extends Component {
  render() {
    const t = this.props.t;

    return (
      <section className="core-feature-section bg-black pt-115 pb-115">
          <div className="container">
            <div className="section-title white-color text-center mb-50">
              <span className="title-tag"> {t('core.subtitle')} </span>
              <h2>{t('core.title')}</h2>
            </div>
            {/* Featre Loop */}
            <div className="row features-loop">
              {
                core_flaticons.length ? core_flaticons.map((icon, i) => (
                  <div className="col-lg-4 col-sm-6 py-3" key={i}>
                    <ReactWOW animation="fadeInLeft" data-wow-delay={0.3*parseInt(i+1)+'s'}>
                    <div className="feature-box dark-box h-100">
                      <div className="icon">
                        <i className={"flaticon-"+icon} />
                      </div>
                      <h3><Link to="#">{t('core.f'+i+'.title')}</Link></h3>
                      <p>
                        {t('core.f'+i+'.desc')}
                      </p>
                      <span className="count">{'0'+parseInt(i+1)}</span>
                    </div>
                    </ReactWOW>
                  </div>
                )): null
              }
            </div>
          </div>
        </section>
        
    );
  }
}

export default Corefeature;
