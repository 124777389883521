import React, { Component } from 'react'
import Headerfour from '../layouts/Headerfour';
import Footer from '../layouts/Footer';
import Roomsidebar from '../layouts/Roomsidebar';
import { Link } from 'react-router-dom';
import i18n from 'i18next'
import Slider from "react-slick"; 
import {rooms} from '../constants'

function importAll(r) {
  return r.keys().map(r);
}

function SampleNextArrow(props) {
  const { onClick } = props;
  return (
    <div className="slick-arrow next-arrow" onClick={onClick}><i className="fal fa-arrow-right"></i></div>
  );
}

function SamplePrevArrow(props) {
  const { onClick } = props;
  return (
    <div className="slick-arrow prev-arrow" onClick={onClick}><i className="fal fa-arrow-left"></i></div>
  );
}

class Roomdetails extends Component {
  constructor(props) {
    super(props);
    this.next = this.next.bind(this);
    this.prev = this.prev.bind(this);
  }
  next() {
    this.slider.slickNext();
  }
  prev() {
    this.slider.slickPrev();
  }
  render() {
    const t = function(obj) {
      return i18n.t(obj);
    }

    const settings = {
        slidesToShow: 1,
        slidesToScroll: 1,
        lazyLoad: true,
        fade: false,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 4048,
        arrows: false,
        dots: false,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />
    };

    const path = window.location.pathname;

    let Room, gallery, RelatedRooms = [];

    rooms.map(el => {
        if (el.url === path) {
            Room = el;
            gallery = importAll(el.gallery);
        }

        return Room;
    })

    Room.related.map((el, i) => {
      return RelatedRooms[i] = rooms[el];
    })

    const bg_room = {
      backgroundImage: `url(${Room.photo})`
    }

    return (
      <div>
        <Headerfour />
        {/*====== BREADCRUMB PART START ======*/}
        <section className="breadcrumb-area" style={bg_room}>
          <div className="container">
            <div className="breadcrumb-text">
              <span>{t('room.subtitle')}</span>
              <h2 className="page-title">{Room.title}</h2>
              <ul className="breadcrumb-nav">
                <li><Link to="/">{t('header.home')}</Link></li>
                <li className="active">{Room.title}</li>
              </ul>
            </div>
          </div>
        </section>
        {/*====== BREADCRUMB PART END ======*/}
        {/*====== ROOM-DETAILS START ======*/}
        <section className="room-details pt-120 pb-90">
          <div className="container">
            <div className="row">
              {/* details */}
              <div className="col-lg-8">
                <div className="deatils-box">
                  <div className="title-wrap">
                    <div className="title">
                      {/* <div className="room-cat">double bed</div> */}
                      <h2>{Room.title}</h2>
                    </div>
                    <div className="price">
                      {Room.price}<span>/{t('booking.night')}</span>
                    </div>
                  </div>
                  <Slider className="thumb" ref={slider => (this.slider = slider)} {...settings}>
                    {gallery.map((item, i) => (
                        <div key={i}>
                            <img src={item} alt={`img-${i}`} effect="blur" />
                        </div>
                    ))}
                  </Slider>
                  <div className="row">
                    <div className="col-8">
                      <p className="h5">
                        {t('room.description')}
                      </p>
                    </div>
                    <div className="col-4">
                      <div className="feature-room-arrow arrow-style text-right mb-5">
                        <SamplePrevArrow onClick={this.prev} />
                        <SampleNextArrow onClick={this.next} />
                      </div>
                    </div>
                  </div>
                  <div className="room-fearures clearfix mt-60 mb-60">
                    <h3 className="subtitle">{t('room.amenities.title')}</h3>
                    <ul className="room-fearures-list">
                      <li><i className="fal fa-wind" />{t('room.amenities.ac')}</li>
                      <li><i className="fal fa-wifi" />{t('room.amenities.wifi')}</li>
                      <li><i className="fal fa-key" />{t('room.amenities.locker')}</li>
                      <li><i className="fal fa-concierge-bell" />{t('room.amenities.breakfast')}</li>
                      <li><i className="fal fa-lock" />{t('room.amenities.security')}</li>
                      <li><i className="fal fa-broom" />{t('room.amenities.cleaning')}</li>
                      <li><i className="fal fa-shower" />{t('room.amenities.shower')}</li>
                      <li><i className="fal fa-headphones-alt" />{t('room.amenities.support')}</li>
                      <li><i className="fal fa-shopping-basket" />{t('room.amenities.grocery')}</li>
                      <li><i className="fal fa-bed" />{t('room.amenities.bed')}</li>
                      <li><i className="fal fa-users" />{t('room.amenities.expert')}</li>
                      <li><i className="fal fa-shopping-cart" />{t('room.amenities.shop')}</li>
                      <li><i className="fal fa-bus" />{t('room.amenities.towels')}</li>
                    </ul>
                  </div>
                  <div className="room-rules clearfix mb-60">
                    <h3 className="subtitle">{t('room.rules.title')}</h3>
                    <ul className="room-rules-list">
                      <li>{t('room.rules.kids')}</li>
                      <li>{t('room.rules.children')}</li>
                      <li>{t('room.rules.smoking')}</li>
                      <li>{t('room.rules.checkin')}</li>
                      <li>{t('room.rules.parking')}</li>
                    </ul>
                  </div>
                  <div className="cancellation-box clearfix mb-60">
                    <h3 className="subtitle">{t('room.cancellation.title')}</h3>
                    <p>
                      {t('room.cancellation.description')}
                    </p>
                  </div>
                  <div className="related-room">
                    <h3 className="subtitle">{t('room.related')}</h3>
                    <div className="row room-gird-loop">
                      {RelatedRooms.map((item, i) => (
                        <div key={i} className="col-lg-6 col-sm-6">
                          <div className="room-box">
                              <div className="room-img-wrap">
                              <div className="room-img" style={{backgroundImage: "url("+ item.photo + ")"}} />
                              </div>
                              <div className="room-desc">
                              <ul className="icons">
                                  <li><i className="fal fa-bed" /></li>
                                  <li><i className="fal fa-wifi" /></li>
                                  <li><i className="fal fa-car" /></li>
                                  <li><i className="fal fa-coffee" /></li>
                                  <li><i className="fal fa-concierge-bell" /></li>
                                  <li><i className="fal fa-compress-arrows-alt" /></li>
                              </ul>
                              <h4 className="title"><Link to={item.url}>{item.title}</Link></h4>
                              <p>{t(item.desc)}</p>
                              <span className="price">{item.price + '/' + t('booking.night')}</span>
                              <Link to={item.url} className="book-btn">{t(item.book)}</Link>
                              </div>
                          </div>
                        </div>
                    ))}
                    </div>
                  </div>
                </div>
              </div>
              {/* form */}
              <div className="col-lg-4">
                <Roomsidebar />
              </div>
            </div>
          </div>
        </section>
        {/*====== ROOM-DETAILS END ======*/}
        <Footer />
      </div>

    );
  }
}

export default Roomdetails;
