import React, { Component } from 'react'
import { social } from '../constants';
import i18n from 'i18next'

class SocialHubs extends Component {
    render() {
        const t = o => {
            return i18n.t(o);
        }

        const animation = this.props.animation;

        return (
            <div className="social-hubs">
                {social.length > 0 ? social.map((el, i) => (
                    <a key={i} href={el.link} target="_blank" rel="noopener noreferrer external nofollow"><i className={el.icon} /></a>
                    )) : null
                }
                <span className={animation ? 'animated' : ''}>{t('live.chat')}</span>
            </div>
        );
    }
}

export default SocialHubs;