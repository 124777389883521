import React, { Component } from 'react'
import Headerfour from '../layouts/Headerfour';
import Footer from '../layouts/Footer';
import Breadcrumb from '../sections/about/Breadcrumb';
import Aboutpage from '../sections/about/About';
import Corefeature from '../sections/about/Corefeature';
// import News from '../sections/about/News';
import i18n from 'i18next'

class About extends Component {
  render() {
    const t = o => {
      return i18n.t(o);
    }

    return (
      <div>
        <Headerfour />
        {/*====== BREADCRUMB PART START ======*/}
        <Breadcrumb t={t} />
        {/*====== BREADCRUMB PART END ======*/}
        {/*====== ABOUT PART START ======*/}
        <Aboutpage t={t} />
        {/*====== ABOUT PART END ======*/}
        {/*====== CORE FEATURES START ======*/}
        <Corefeature t={t} />
        {/*====== CORE FEATURES END ======*/}
        {/*====== LATEST NEWS START ======*/}
        {/* <News t={t} /> */}
        {/*====== LATEST NEWS END ======*/}
        <Footer />
      </div>
    );
  }
}

export default About;
