import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import 'react-responsive-modal/styles.css';
// import { Modal } from 'react-responsive-modal';
import ReactWOW from 'react-wow'

class About extends Component {
  // state = {
  //   open: false,
  //   };
  //   onOpenModal = () => {
  //   this.setState({ open: true });
  //   };
    
  //   onCloseModal = () => {
  //   this.setState({ open: false });
  //   };
    render() {
    // const { open } = this.state;
    const t = this.props.t;

    return (
      <section className="about-section pt-115 pb-115">
          <div className="container">
            <div className="video-wrap video-wrap-two video-about mb-60" style={{backgroundImage: 'url(assets/img/text-block/04.jpg)'}}>
              {/* <div className="popup-video" onClick={this.onOpenModal} ><i className="fas fa-play" /></div>
              <Modal open={open} onClose={this.onCloseModal} center classNames={{
                  modal: 'video-modal',
                  }}>
                  <iframe src="https://www.youtube.com/embed/EEJFMdfraVY" title="title"></iframe>
                  </Modal> */}
            </div>
            <div className="section-title about-title text-center">
              <span className="title-tag"><span>MR BEYAZ BOUTIQUE HOTEL</span></span>
            </div>
            <p className="mb-3 text-justify">{t('about.p1')}</p>
            <p className="mb-3 text-justify">{t('about.p2')}</p>
            <p className="mb-3 text-justify">{t('about.p3')}</p>
            <ul className="about-features">
              <ReactWOW animation="fadeInUp" data-wow-delay=".3s">
              <li>
                <Link to="/restaurant">
                  <i className="flaticon-coffee" />
                  <i className="hover-icon flaticon-coffee" />
                  <span className="title">{t('restaurant.name')}</span>
                </Link>
              </li></ReactWOW>
              <ReactWOW animation="fadeInUp" data-wow-delay=".4s">
              <li>
                <Link to="/contact">
                  <i className="flaticon-air-freight" />
                  <i className="hover-icon flaticon-air-freight" />
                  <span className="title">{t('contact.us')}</span>
                </Link>
              </li></ReactWOW>
              <ReactWOW animation="fadeInUp" data-wow-delay=".5s">
              <li>
                <Link to="/places">
                  <i className="flaticon-marker" />
                  <i className="hover-icon flaticon-marker" />
                  <span className="title">{t('sidemenu.places')}</span>
                </Link>
              </li></ReactWOW>
              <ReactWOW animation="fadeInUp" data-wow-delay=".6s">
              <li>
                <Link to="/offers">
                  <i className="flaticon-hotel" />
                  <i className="hover-icon flaticon-hotel" />
                  <span className="title">{t('header.rooms')}</span>
                </Link>
              </li></ReactWOW>
            </ul>
            <div className="about-text-box">
              <div className="row align-items-center">
                <div className="col-lg-6">
                  <div className="about-img">
                    <img src="assets/img/text-block/05.jpg" alt="" />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="about-text">
                    <span>{t('restaurant.name')}</span>
                    <h3>{t('restaurant.about.title1')} <br/> {t('restaurant.about.title2')}</h3>
                    <p>
                      {t('restaurant.about.description')}
                    </p>
                    <Link to="/restaurant" className="main-btn btn-filled">{t('take.a.tour')}</Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        
    );
  }
}

export default About;
