import React, { Component } from 'react';
import { Link } from 'react-router-dom'; 
import Slider from "react-slick";
import ReactWOW from 'react-wow'

const bannerPosts = [
  {
      photo: 'assets/img/banner/01.jpg',
      tag: 'banner.slide1.subtitle',
      taganimation: '.6s',
      title:"banner.slide1.title",
      titleanimation:'.9s',
      btn1:'take.a.tour',
      btn1animation:'1.1s',
      btn1url:'/gallery',
      btn2:'learn.more',
      btn2animation:'1.3s',
      btn2url:'/about',
  },{
    photo: 'assets/img/banner/02.jpg',
    tag: 'banner.slide1.subtitle',
    taganimation: '.6s',
    title:"banner.slide1.title",
    titleanimation:'.9s',
    btn1:'take.a.tour',
    btn1animation:'1.1s',
    btn1url:'/gallery',
    btn2:'learn.more',
    btn2animation:'1.3s',
    btn2url:'/about',
  },{
    photo: 'assets/img/banner/03.jpg',
    tag: 'banner.slide1.subtitle',
    taganimation: '.6s',
    title:"banner.slide1.title",
    titleanimation:'.9s',
    btn1:'take.a.tour',
    btn1animation:'1.1s',
    btn1url:'/gallery',
    btn2:'learn.more',
    btn2animation:'1.3s',
    btn2url:'/about',
  }
];


class Banner extends Component {
  render() {
    const settings = {
        infinite: true,
        autoplay: true,
        autoplaySpeed: 5000,
        dots: false,
        fade: true,
        arrows: false,
        pauseOnHover: false
    };
    
    const t = this.props.t;
    
    return (
    <Slider className="banner-area banner-style-two" id="bannerSlider" {...settings}>
      {bannerPosts.map((item, i) => (
        <div key={i} className="single-banner d-flex align-items-center justify-content-center">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-12 text-center">
                <div className="banner-content">
                  <ReactWOW animation="fadeInDown" data-delay=".3s">
                    <div>
                      <img src="assets/img/logo-white.png" alt="logo" className="img-fluid main-logo" />
                    </div>
                  </ReactWOW>
                  <ReactWOW animation="fadeInDown" data-delay={item.taganimation}>
                    <span className="promo-tag">{t(item.tag)}</span>
                  </ReactWOW>
                  <ReactWOW animation="fadeInLeft" data-delay={item.titleanimation}>
                    <h1 className="title">{t(item.title)}</h1>
                  </ReactWOW>
                  <ul>
                  <ReactWOW animation="fadeInUp" data-delay={item.btn1animation}>
                    <li>
                      <Link className="main-btn btn-filled" to={item.btn1url}>{t(item.btn1)}</Link>
                    </li></ReactWOW>
                    <ReactWOW animation="fadeInUp" data-delay={item.btn2animation}>
                    <li>
                      <Link className="main-btn btn-border" to={item.btn2url}>{t(item.btn2)}</Link>
                    </li></ReactWOW>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          {/* banner bg */}
          <div className="banner-bg" style={{backgroundImage: `url(${item.photo})`}} />
          <div className="banner-overly" />
        </div>
      ))}
    </Slider>
    );
  }
}

export default Banner;
